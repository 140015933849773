<div
    [ngSwitch]="wts.isNCBankAccountAvailable(initData)"
    appLinkify
    [linkConfigPath]="i18nBasePath + '.linkConfigs'">
    <!-- Add Mode -->
    <ng-container *ngSwitchCase="false">
        <!-- Plaid ACH -->
        <app-payment-item
            [style]="PAYMENT_BUTTON_STYLE.Bordered"
            [isPaymentLinked]="false"
            (clickContainer)="upsertBankAccount.emit()">
            <app-payment-item-header>
                {{ wts.getI18nLabel(i18nBasePath, 'addMode.plaid') }}
            </app-payment-item-header>
        </app-payment-item>
    </ng-container>

    <!-- Edit Mode -->
    <ng-container *ngSwitchDefault>
        <app-payment-item [isPaymentLinked]="true">
            <app-payment-item-header>
                {{ wts.getI18nLabel(i18nBasePath, 'editMode.title') }}

                <span
                    class="payment-subtitle payment-subtitle-faded payment-subtitle-hint"
                    *ngIf="wts.isNCBankAccountAvailable(initData)">
                    {{ wts.getI18nLabel(i18nBasePath, 'editMode.hint') }}
                </span>
            </app-payment-item-header>

            <app-payment-item-detail>
                {{ wts.getI18nLabel(i18nBasePath, 'editMode.details.name') }} {{ bankAccountResponse?.details.accountName }}
            </app-payment-item-detail>

            <app-payment-item-detail>
                {{ wts.getI18nLabel(i18nBasePath, 'editMode.details.number') }}
                {{ bankAccountResponse?.details.accountNumber | lastDigits }}
            </app-payment-item-detail>

            <app-payment-item-detail>
                {{ wts.getI18nLabel(i18nBasePath, 'editMode.details.type') }} {{ bankAccountResponse?.details.accountType }}
            </app-payment-item-detail>

            <app-payment-item-actions>
                <button
                    nz-button
                    nzSize="small"
                    type="button"
                    (click)="upsertBankAccount.emit()">
                    {{ wts.getI18nLabel(i18nBasePath, 'editMode.actions.update') }}
                </button>
            </app-payment-item-actions>
        </app-payment-item>
    </ng-container>
</div>
