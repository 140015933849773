export const RegCFLimitEnI18n = {
    title: `Your Investment Limits`,
    descr: {
        label: `The SEC regulates how much you can invest in Reg CF offerings. The limit is based on your previous investments in this type of offerings in last 12 months as well as your net worth and annual income. 
        <a data-link="learnMoreLink"></a> <br/> <br/> Please enter total nominal investment amount you have invested in other Reg CF offerings on any other platform in the last 12 months.`,
    },
    alerts: {
        lowLimit: {
            text: `Your current limit for Regulation CF is less than minimum purchase amount for this offering. Provide additional information to recalculate your limit.`,
        },
    },
    form: {
        fields: {
            externalInvestment: {
                label: `External Reg CF Investments`,
            },
            yearlyIncome: {
                label: `Yearly Income`,
            },
            netWorth: {
                label: `Net Worth`,
            },
            limitCheckbox: {
                label: `I intend to spend more than the calculated limit`,
            },
            recalculateCta: {
                label: `Recalculate Limit`,
                btnClass: `ant-btn-sm`,
            },
            genericValidators: {
                required: '{{ fieldLabel }} is required',
            },
        },
    },
    preview: {
        descr: `Reg CF Limit (per 12 months)`,
        popover: `The calculation is based on the financial information you provided with consideration of the Reg CF purchases you made within Baza previously, if any.`,
    },
    footer: {
        cta: {
            text: `Save`,
            btnClass: `ant-btn-primary ant-btn-block`,
        },
        backLinkText: `Back to Listing Details`,
    },
    linkConfigs: [
        {
            key: 'learnMoreLink',
            link: `https://www.sec.gov/oiea/investor-alerts-bulletins/ib-crowdfunding`,
            text: `Learn More.`,
        },
    ],
};
