import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BazaFormValidatorService, BazaLoadingService } from '@scaliolabs/baza-core-ng';
import { BazaNcBankAccountGetDefaultByTypeResponse } from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService, Message, PriceCentsPipe, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { WithdrawFundsFormComponent } from '../withdraw-funds-form/withdraw-funds-form.component';

@Component({
    selector: 'app-withdraw-funds-modal',
    templateUrl: './withdraw-funds-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UtilModule, NzModalModule, NzButtonModule, WithdrawFundsFormComponent],
})
export class WithdrawFundsModalComponent {
    @Input()
    withdrawFundsForm?: UntypedFormGroup;

    @Input()
    dwollaDefaultCashOutAccount?: BazaNcBankAccountGetDefaultByTypeResponse;

    @Input()
    withdrawFundsError?: Message;

    @Input()
    walletAmount = 0;

    @Output()
    withdrawFundsCancel = new EventEmitter();

    @Output()
    submitWithdrawFundsForm = new EventEmitter();

    @ViewChild('withdrawFundsFormComp') withdrawFundsFormCompRef: WithdrawFundsFormComponent;

    i18nBasePath = 'uic.withdrawFunds.modal';

    constructor(
        public readonly bazaFormValidatorService: BazaFormValidatorService,
        public readonly wts: BazaWebUtilSharedService,
        private readonly pricePipe: PriceCentsPipe,
        public readonly loading: BazaLoadingService,
    ) {}

    onFormSubmit(modalEl = null): void {
        const formElRef = this.withdrawFundsFormCompRef?.withdrawFundsFormRef?.nativeElement ?? null;

        if (this.bazaFormValidatorService.isFormValid(this.withdrawFundsForm, formElRef, modalEl)) {
            this.submitWithdrawFundsForm.emit();
        }
    }

    get balanceLabel() {
        return this.wts.getI18nLabel(this.i18nBasePath, 'subtitle', { amount: this.pricePipe.transform(this.walletAmount) });
    }
}
