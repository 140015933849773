<nz-card
    class="ant-card-transparent news-card"
    [class.news-card-locked]="post.isLocked"
    [nzBordered]="false">
    <div
        class="news-card__media"
        *ngIf="post?.type === POST_TYPE.Image || post?.type === POST_TYPE.Article || post?.type === POST_TYPE.Video">
        <div
            class="news-card__blur"
            [style.background-image]="bgImg"></div>

        <img
            class="news-card__image"
            [src]="post?.previewImageUrl"
            *ngIf="post?.type === POST_TYPE.Image || post?.type === POST_TYPE.Article" />

        <video
            class="news-card__video"
            controls="controls"
            *ngIf="post?.type === POST_TYPE.Video">
            <source
                type="video/mp4"
                [src]="post?.previewVideoUrl" />
        </video>

        <!-- News Media - Blur Section (For Image, Video, Article)-->
        <ng-template [ngTemplateOutlet]="blur"></ng-template>
    </div>

    <div class="news-card__content">
        <div class="news-card__subtitle">
            <div
                class="news-card__tag"
                *ngIf="post?.tags[0]?.title">
                {{ post?.tags[0]?.title }}
            </div>
            <div
                class="news-card__date"
                *ngIf="post?.datePublishedAt">
                {{ post?.datePublishedAt | date : 'MMM dd' }}
            </div>
        </div>

        <h3
            *ngIf="post?.title"
            class="news-card__title"
            [innerHTML]="post?.title"></h3>

        <div
            class="news-card__descr"
            *ngIf="
                (!post.isLocked &&
                    (post?.type === POST_TYPE.Image || post?.type === POST_TYPE.Article || post?.type === POST_TYPE.Video)) ||
                post?.type === 'Text'
            ">
            <div
                class="news-card__text"
                [class.news-card__text-blur]="post?.isLocked">
                <p
                    *ngIf="post?.intro"
                    class="news-card__intro"
                    [innerHTML]="post?.intro | sanitizeHtml"></p>
                <div
                    class="news-card__expanded"
                    *ngIf="isExpanded && post?.contents"
                    [innerHTML]="post?.contents | sanitizeHtml"></div>
                <div
                    class="news-card__more"
                    *ngIf="!post?.isLocked && post?.contents">
                    <a
                        role="button"
                        (click)="expand.emit()">
                        {{ isExpanded ? 'Read less' : 'Read more' }}
                    </a>
                </div>
            </div>

            <!-- News Description - Blur Section (For Text)-->
            <ng-template [ngTemplateOutlet]="blur"></ng-template>
        </div>

        <div
            class="news-card__actions"
            *ngIf="!post?.isLocked">
            <div class="news-card__favorite">
                <a
                    role="button"
                    (click)="applause.emit()">
                    <i
                        class="icon"
                        [ngClass]="post.isApplause ? 'icon-heart' : 'icon-heart-o'"></i>
                </a>

                <span
                    class="news-card__applause"
                    *ngIf="post.applause">
                    {{ post.applause }}
                </span>
            </div>

            <div class="news-card__share">
                <a role="button">
                    <i class="icon icon-share"></i>
                </a>
            </div>
        </div>

        <!-- Blur CTA -->
        <div
            class="news-card__cta"
            *ngIf="post?.isLocked">
            <a [routerLink]="['/items', post?.listing?.id]">
                <button
                    nz-button
                    nzBlock
                    nzType="primary"
                    [class.ant-btn-sm]="innerWidth < 768">
                    View Property
                </button>
            </a>
        </div>
    </div>
</nz-card>

<ng-template #blur>
    <div
        class="news-blur"
        *ngIf="post?.isLocked">
        <div class="news-blur__icon">
            <svg-icon-sprite src="lock"></svg-icon-sprite>
        </div>

        <div class="news-blur__text">Become an investor to gain access to exclusive materials</div>
    </div>
</ng-template>
