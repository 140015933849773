import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IframeComponent } from './iframe.component';

@NgModule({
    declarations: [IframeComponent],
    exports: [IframeComponent],
    imports: [CommonModule],
})
export class IframeModule {}
