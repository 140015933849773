import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { BazaNcIntegrationFeedDataAccess } from '@scaliolabs/baza-nc-integration-data-access';
import {
    BazaNcIntegrationFeedPostDto,
    FeedApplauseResponse,
    FeedHasAnyUpdatesResponse,
    FeedListResponse,
    FeedMarkAllAsReadResponse,
    FeedUnapplauseResponse,
} from '@scaliolabs/baza-nc-integration-shared';
import { FeedGetByIdResponse } from '@scaliolabs/baza-nc-integration-shared';
import { EffectsUtil } from '@scaliolabs/baza-web-utils';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Applause, FeedHasAnyUpdates, GetFeed, GetFeedById, MarkAllAsRead, Unapplause } from './actions';

export interface FeedStateModel {
    current: { post: BazaNcIntegrationFeedPostDto };
    hasAnyUpdates: boolean;
    isEndOfFeed: boolean;
    posts: BazaNcIntegrationFeedPostDto[];
}

@Injectable()
@State<FeedStateModel>({
    name: 'feed',
    defaults: {
        current: undefined,
        hasAnyUpdates: false,
        isEndOfFeed: false,
        posts: undefined,
    },
})
export class FeedState {
    constructor(private readonly effectsUtil: EffectsUtil, private readonly feedDataAccess: BazaNcIntegrationFeedDataAccess) {}

    @Selector()
    static current(state: FeedStateModel): BazaNcIntegrationFeedPostDto {
        return state.current.post;
    }

    @Selector()
    static posts(state: FeedStateModel): BazaNcIntegrationFeedPostDto[] {
        return state.posts;
    }

    @Selector()
    static isEndOfFeed(state: FeedStateModel): boolean {
        return state.isEndOfFeed;
    }

    @Selector()
    static hasAnyUpdates(state: FeedStateModel): boolean {
        return state.hasAnyUpdates;
    }

    @Action(GetFeed, { cancelUncompleted: true })
    getFeed(ctx: StateContext<FeedStateModel>, action: GetFeed): Observable<FeedListResponse> {
        return this.feedDataAccess
            .list({
                lastId: action.lastId,
            })
            .pipe(
                tap((response: FeedListResponse) => {
                    ctx.patchState({
                        posts: action.lastId ? [...ctx.getState().posts, ...response.posts] : response.posts,
                        isEndOfFeed: response.isEndOfFeed,
                    });
                }),
                this.effectsUtil.tryCatchError$(''),
            );
    }

    @Action(GetFeedById, { cancelUncompleted: true })
    getFeedById(ctx: StateContext<FeedStateModel>, action: GetFeedById): Observable<FeedGetByIdResponse> {
        return this.feedDataAccess.getById({ id: action.id }).pipe(
            tap((response) => {
                return ctx.patchState({
                    current: response,
                });
            }),
            this.effectsUtil.tryCatchError$(''),
        );
    }

    @Action(FeedHasAnyUpdates, { cancelUncompleted: true })
    hasAnyUpdates(ctx: StateContext<FeedStateModel>): Observable<FeedHasAnyUpdatesResponse> {
        return this.feedDataAccess.hasAnyUpdates().pipe(
            tap((response: FeedHasAnyUpdatesResponse) => {
                ctx.patchState({
                    hasAnyUpdates: response.hasAnyUpdates,
                });
            }),
        );
    }

    @Action(MarkAllAsRead, { cancelUncompleted: true })
    markAllAsRead(ctx: StateContext<FeedStateModel>, action: MarkAllAsRead): Observable<FeedMarkAllAsReadResponse> {
        return this.feedDataAccess.markAllAsRead(action).pipe(
            tap(() => {
                ctx.patchState({
                    hasAnyUpdates: false,
                });
            }),
            this.effectsUtil.tryCatchError$(''),
        );
    }

    @Action(Applause, { cancelUncompleted: true })
    applause(ctx: StateContext<FeedStateModel>, action: Applause): Observable<FeedApplauseResponse> {
        const posts = ctx.getState()?.posts;
        const currentPost = ctx.getState()?.current;

        return this.feedDataAccess.applause({ id: action.post.id }).pipe(
            tap((response: FeedApplauseResponse) => {
                if (currentPost?.post?.id === action?.post?.id) {
                    ctx.patchState({
                        current: {
                            ...currentPost,
                            post: {
                                ...currentPost?.post,
                                isApplause: !action.post?.isApplause,
                                applause: response?.applause,
                            },
                        },
                    });
                } else if (posts) {
                    ctx.setState(
                        patch<FeedStateModel>({
                            posts: updateItem(
                                (post) => post?.id === action?.post?.id,
                                patch({ isApplause: !action.post?.isApplause, applause: response?.applause }),
                            ),
                        }),
                    );
                }
            }),
            this.effectsUtil.tryCatchError$(''),
        );
    }

    @Action(Unapplause, { cancelUncompleted: true })
    unapplause(ctx: StateContext<FeedStateModel>, action: Unapplause): Observable<FeedUnapplauseResponse> {
        const posts = ctx.getState()?.posts;
        const currentPost = ctx.getState()?.current;

        return this.feedDataAccess.unapplause({ id: action.post.id }).pipe(
            tap((response: FeedUnapplauseResponse) => {
                if (currentPost?.post?.id === action?.post?.id) {
                    ctx.patchState({
                        current: {
                            ...currentPost,
                            post: {
                                ...currentPost?.post,
                                isApplause: !action.post?.isApplause,
                                applause: response?.applause,
                            },
                        },
                    });
                } else if (posts) {
                    ctx.setState(
                        patch<FeedStateModel>({
                            posts: updateItem(
                                (post) => post?.id === action?.post?.id,
                                patch({ isApplause: !action.post?.isApplause, applause: response?.applause }),
                            ),
                        }),
                    );
                }
            }),
            this.effectsUtil.tryCatchError$(''),
        );
    }
}
