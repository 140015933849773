import { Injectable } from '@angular/core';
import { CanActivateChild } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { flatMap, skipWhile } from 'rxjs/operators';
import { PurchaseState, StartCart } from '@scaliolabs/baza-web-purchase-flow';

export const CART_IS_EMPTY = 'CART_IS_EMPTY';
export const NAVIGATION_CANCELING_ERROR = 'ngNavigationCancelingError';

export function makeCancelingError(error: Error) {
    error[`${NAVIGATION_CANCELING_ERROR}`] = true;
    return error;
}

@Injectable()
export class CartGuard implements CanActivateChild {
    constructor(private readonly store: Store) {
        this.store.dispatch(new StartCart());
    }

    canActivateChild(): Observable<boolean> {
        return this.store.select(PurchaseState.cart).pipe(
            skipWhile((p) => p === undefined),
            flatMap((response) => {
                return response !== null ? of(true) : throwError(makeCancelingError(new Error(CART_IS_EMPTY)));
            }),
        );
    }
}
