<ul class="footer-menu">
    <ng-container *ngIf="!user">
        <li
            class="footer-menu__item"
            nzMatchRouter>
            <a
                class="footer-menu__link"
                [routerLink]="['/']">
                Home
            </a>
        </li>

        <li
            class="footer-menu__item"
            nzMatchRouter>
            <a
                class="footer-menu__link"
                [routerLink]="['/items']">
                Listings
            </a>
        </li>

        <li
            class="footer-menu__item"
            nzMatchRouter>
            <a
                class="footer-menu__link"
                [routerLink]="['', { outlets: { modal: ['auth', 'login'] } }]"
                [state]="{ skipScroll: true }">
                Sign In
            </a>
        </li>

        <li
            class="footer-menu__item"
            nzMatchRouter>
            <a
                class="footer-menu__link"
                [routerLink]="['', { outlets: { modal: ['auth', 'signup'] } }]"
                [state]="{ skipScroll: true }">
                Sign Up
            </a>
        </li>
    </ng-container>

    <ng-container *ngIf="user">
        <li
            class="footer-menu__item"
            nzMatchRouter>
            <a
                class="footer-menu__link"
                [routerLink]="['/']">
                Home
            </a>
        </li>

        <li
            class="footer-menu__item"
            nzMatchRouter>
            <a
                class="footer-menu__link"
                [routerLink]="['/items']">
                Listings
            </a>
        </li>

        <li
            class="footer-menu__item"
            nzMatchRouter>
            <a
                class="footer-menu__link"
                [routerLink]="['/portfolio']">
                My Portfolio
            </a>
        </li>

        <li
            class="footer-menu__item"
            nzMatchRouter>
            <a
                class="footer-menu__link"
                [routerLink]="['/favorites']">
                Favorites
            </a>
        </li>
    </ng-container>
</ul>
