import { Injectable } from '@angular/core';
import { Action, State, Selector, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { BazaNcTaxDocumentDataAccess } from '@scaliolabs/baza-nc-data-access';
import { BazaNcTaxDocumentDto, BazaNcTaxDocumentListResponse } from '@scaliolabs/baza-nc-shared';
import { EffectsUtil } from '@scaliolabs/baza-web-utils';

import { GetTaxDocument } from './actions';

export interface TaxDocumentStateModel {
    items: BazaNcTaxDocumentDto[];
}

@State<TaxDocumentStateModel>({
    name: 'taxDocument',
    defaults: {
        items: [],
    },
})
@Injectable()
export class TaxDocumentState {
    @Selector()
    static items(state: TaxDocumentStateModel): BazaNcTaxDocumentDto[] {
        return state ? state.items : [];
    }

    constructor(private readonly effectsUtil: EffectsUtil, private readonly taxDocumentService: BazaNcTaxDocumentDataAccess) {}

    @Action(GetTaxDocument, { cancelUncompleted: true })
    getTaxDocument(ctx: StateContext<TaxDocumentStateModel>) {
        return this.taxDocumentService.list({ size: -1 }).pipe(
            tap((response: BazaNcTaxDocumentListResponse) => {
                ctx.patchState({ items: response.items });
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }
}
