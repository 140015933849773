<nz-footer class="footer footer-mini">
    <div class="container">
        <div
            nz-row
            nzGutter="30">
            <div
                nz-col
                nzXs="12">
                &copy; {{ today | date : 'YYYY' }} Scalio. All rights reserved.
                <br class="hide-tablet hide-desktop" />
                Need help? Check our

                <a
                    target="_blank"
                    [href]="getServiceLink('bazaCommon.links.faq') ?? '/faq'">
                    FAQs
                </a>

                or

                <a
                    [routerLink]="['', { outlets: { modal: ['contact-us'] } }]"
                    [state]="{ skipScroll: true }">
                    Contact Us
                </a>
            </div>
        </div>
    </div>
</nz-footer>
