import { SendConfirmEmailLinkRequest } from '@scaliolabs/baza-core-shared';
import {
    ConfirmEmailData,
    DeactivateAccountData,
    RegisterAccountData,
    ResetPasswordData,
    RestorePasswordData,
    SendDeactivateAccountLinkData,
    UpdateAccountData,
    UpdatePasswordData,
} from '../../models';

export class AccountInit {
    static readonly type = '[Account] Init';
}

export class EditingPersonalInformation {
    static readonly type = '[Account] EditingPersonalInformation';
}

export class AccountUnset {
    static readonly type = '[Account] Unset';
}

export class ConfirmEmail {
    static readonly type = '[Account] Confirm Email';

    constructor(public data: ConfirmEmailData) {}
}

export class DeactivateAccount {
    static readonly type = '[Account] Deactivate Account';

    constructor(public data: DeactivateAccountData) {}
}

export class RegisterAccount {
    static readonly type = '[Account] Register Account';

    constructor(public data: RegisterAccountData) {}
}

export class ResetPassword {
    static readonly type = '[Account] Reset Password';

    constructor(public readonly data: ResetPasswordData) {}
}

export class ResendConfirmation {
    static readonly type = '[Account] Resend Confirmation';

    constructor(public readonly data: SendConfirmEmailLinkRequest) {}
}

export class SendDeactivateAccountLink {
    static readonly type = '[Account] Send Deactivate Account Link';

    constructor(public readonly data: SendDeactivateAccountLinkData) {}
}

export class SendResetPasswordLink {
    static readonly type = '[Account] Send Reset Password Link';

    constructor(public data: RestorePasswordData) {}
}

export class UpdateAccount {
    static readonly type = '[Account] Update Account';

    constructor(public readonly data: UpdateAccountData) {}
}

export class UpdatePassword {
    static readonly type = '[Account] Update Password';

    constructor(public readonly data: UpdatePasswordData) {}
}
