<div
    class="regcf-overview"
    appLinkify
    [linkConfigPath]="i18nBasePath + '.linkConfigs'">
    <nz-card>
        <div class="regcf-overview__section">
            <div class="regcf-overview__icon">
                <svg-icon-sprite src="price-check"></svg-icon-sprite>
            </div>

            <div class="regcf-overview__text">
                <div
                    class="regcf-overview__descr"
                    [innerHTML]="wts.getI18nLabel(i18nBasePath, 'descr')"></div>

                <div class="regcf-overview__cta">
                    <a
                        (click)="updateLimit.next()"
                        [innerHTML]="wts.getI18nLabel(i18nBasePath, 'updateCtaText')"></a>
                </div>
            </div>
        </div>
    </nz-card>
</div>
