<ng-container
    *ngIf="{
        isHeaderGhost: isHeaderGhost$ | async,
        user: user$ | async
    } as data">
    <app-dividend-alert></app-dividend-alert>

    <nz-header
        class="header"
        [class.header-ghost]="data.isHeaderGhost">
        <div class="container header__container">
            <a
                class="header__logo"
                [routerLink]="['/']">
                <img
                    src="/assets/images/logo.svg"
                    alt="Baza" />
            </a>

            <button
                class="header-icon"
                title="Menu"
                [class.header-icon-ghost]="data.isHeaderGhost"
                [class.header-icon-open]="openedMenu"
                (click)="this.onMenuToggle()">
                <span class="line line-1"></span>
                <span class="line line-2"></span>
                <span class="line line-3"></span>
            </button>

            <div
                class="header__wrap"
                [class.header__wrap-open]="openedMenu">
                <ul
                    class="header-menu"
                    nz-menu
                    nzMode="horizontal"
                    [nzTheme]="data.isHeaderGhost ? 'dark' : 'light'">
                    <li
                        nz-menu-item
                        [nzMatchRouterExact]="true"
                        nzMatchRouter>
                        <a [routerLink]="['/']">Home</a>
                    </li>

                    <li
                        nz-menu-item
                        nzMatchRouter>
                        <a [routerLink]="['/items']">Listings</a>
                    </li>

                    <li
                        nz-menu-item
                        nzMatchRouter
                        *ngIf="data.user">
                        <a [routerLink]="['/portfolio']">My Portfolio</a>
                    </li>

                    <li
                        nz-menu-item
                        nzMatchRouter>
                        <a [routerLink]="['/news']">
                            The Latest

                            <span
                                class="header-indicator"
                                *ngIf="hasAnyUpdates$ | async"></span>
                        </a>
                    </li>

                    <li
                        nz-menu-item
                        nzMatchRouter
                        *ngIf="data.user">
                        <a [routerLink]="['/favorites']">Favorites</a>
                    </li>

                    <li
                        class="hide-desktop"
                        nz-menu-item
                        nzMatchRouter
                        *ngIf="data.user">
                        <a [routerLink]="['/account']">Account</a>
                    </li>

                    <li class="header-menu__space"></li>

                    <li
                        class="hide-desktop"
                        nz-menu-item
                        *ngIf="data.user">
                        <a
                            role="button"
                            (click)="onLogout()">
                            Log Out
                        </a>
                    </li>

                    <li
                        class="hide-mobile hide-tablet"
                        nz-submenu
                        *ngIf="data.user">
                        <span title>
                            <em class="header__user">
                                Welcome, {{ data.user.firstName }}
                                <i class="icon icon-angle-down header__icon"></i>
                            </em>
                        </span>

                        <ul>
                            <li
                                nz-menu-item
                                nzMatchRouter>
                                <a [routerLink]="['/account']">Account</a>
                            </li>

                            <li nz-menu-item>
                                <a
                                    role="button"
                                    (click)="onLogout()">
                                    Log Out
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>

                <div
                    class="header__action"
                    *ngIf="!data.user">
                    <button
                        class="header__btn"
                        nz-button
                        [nzSize]="openedMenu ? 'default' : 'small'"
                        [nzGhost]="data.isHeaderGhost"
                        [routerLink]="['', { outlets: { modal: ['auth', 'login'] } }]"
                        [state]="{ skipScroll: true }">
                        Sign In
                    </button>

                    <button
                        class="header__btn"
                        nz-button
                        nzType="primary"
                        [nzSize]="openedMenu ? 'default' : 'small'"
                        [routerLink]="['', { outlets: { modal: ['auth', 'signup'] } }]"
                        [state]="{ skipScroll: true }">
                        Sign Up
                    </button>
                </div>
            </div>
        </div>
    </nz-header>
</ng-container>
