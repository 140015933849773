<div
    appLinkify
    [linkConfigPath]="i18nBasePath + '.linkConfigs'">
    <ng-container *ngIf="config?.isAddModeOn">
        <button
            class="payment-btn payment-btn-inverse payment-btn-buy"
            [class.payment-btn-bordered]="config?.style === PAYMENT_BUTTON_STYLE.Bordered"
            nz-button
            nzBlock
            disabled>
            <div class="payment-btn__title">
                {{ wts.getI18nLabel(i18nBasePath, 'title') }}

                <div class="payment-subtitle payment-subtitle-faded">
                    {{ wts.getI18nLabel(i18nBasePath, 'hint') }}
                </div>
            </div>
            <div class="payment-arrow">
                <i class="icon icon-angle-right icon-wt"></i>
            </div>
        </button>
    </ng-container>

    <ng-container *ngIf="config?.isEditModeOn">
        <div
            class="payment-section payment-section-inverse payment-section-buy"
            [class.payment-section-bordered]="config?.style === PAYMENT_BUTTON_STYLE.Bordered"
            [class.payment-section-disabled]="!config?.hasEnoughFunds">
            <div class="payment-section__title payment-section__title-block">
                {{ wts.getI18nLabel(i18nBasePath, 'title') }}

                <div
                    class="payment-subtitle"
                    [class.payment-subtitle-faded]="!config?.hasEnoughFunds">
                    <span class="payment-subtitle__prefix">
                        {{ wts.getI18nLabel(i18nBasePath, 'prefix') }}
                    </span>
                    <span
                        class="payment-subtitle__funds"
                        [class.payment-subtitle__funds-low]="!config?.hasEnoughFunds">
                        {{ srcData?.value | priceCents }}
                    </span>
                </div>
            </div>
        </div>
    </ng-container>
</div>
