import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Verification } from '@scaliolabs/baza-web-verification-flow';
import { BazaNcIntegrationListingsDto } from '@scaliolabs/baza-nc-integration-shared';
import {
    BazaNcBootstrapDto,
    BazaNcOfferingRegulationType,
    BazaNcPurchaseFlowTransactionType,
    KYCStatus,
    PurchaseFlowDto,
    PurchaseFlowInvestorRegulationLimitsDto,
    PurchaseFlowSessionDto,
    StatsDto,
} from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService, LEGACY_NC_MODE_QUERY_PARAM } from '@scaliolabs/baza-web-utils';
import { Observable, combineLatest } from 'rxjs';
import { concatMap, map, switchMap, tap } from 'rxjs/operators';
import {
    CancelPurchase,
    ClearPurchaseStats,
    GetPurchaseStats,
    PatchStartPurchase,
    PurchaseState,
    ResetRegCFLimitFromStats,
    StartPurchase,
} from '../data-access';
import { BazaLoadingService } from '@scaliolabs/baza-core-ng';

@Component({
    selector: 'app-purchase-details',
    templateUrl: './details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseDetailsComponent implements OnInit {
    @Input()
    initData: BazaNcBootstrapDto;

    @Input()
    verification: Verification;

    cart$ = this.store.select(PurchaseState.cart);
    stats$ = this.store.select(PurchaseState.stats);
    numberOfShares$ = this.store.select(PurchaseState.numberOfShares);
    purchaseStart$ = this.store.select(PurchaseState.purchaseStart);
    regCfLimits$ = this.store.select(PurchaseState.regCfLimit);

    numberOfShares: number;
    numberOfSharesMin: number;
    i18nBasePath = 'dwpf.details';

    item: BazaNcIntegrationListingsDto;
    stats: StatsDto;
    regCfLimits: PurchaseFlowInvestorRegulationLimitsDto;

    showRegCfConsentModal = false;
    showRegCfLimitModal = false;
    regCFConsentAccepted = false;

    OFFERING_REGULATION_TYPES = BazaNcOfferingRegulationType;
    pfConfig = this.store.selectSnapshot(PurchaseState.pfConfig);

    dataStream$: Observable<{
        number: number;
        stats: StatsDto;
    }>;

    shareMapping: { [k: string]: string } = {
        '=1': '# share',
        other: '# shares',
    };
    KycStatus = KYCStatus;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly store: Store,
        public readonly wts: BazaWebUtilSharedService,
        public readonly loader: BazaLoadingService,
    ) {
        this.item = this.store.selectSnapshot(PurchaseState.cart);

        // cancel purchase + load stats + check configurations (in order)
        this.dataStream$ = this.store.dispatch(new CancelPurchase({ offeringId: this.item?.offeringId })).pipe(
            concatMap(() => this.store.dispatch(new GetPurchaseStats(this.item?.offeringId, this.getMinPurchaseAmount(this.item)))),
            switchMap(() => {
                return this.initializeDataStream();
            }),
        );
    }

    ngOnInit(): void {
        if (this.wts.isForeignInvestor(this.initData) && !this.route?.snapshot?.queryParams?.foreign) {
            this.router.navigate(['.'], {
                relativeTo: this.route,
                queryParams: {
                    foreign: true,
                },
                queryParamsHandling: 'merge',
            });
        }
    }

    initializeDataStream() {
        return combineLatest([this.numberOfShares$, this.stats$]).pipe(
            map(([number, stats]) => ({
                number,
                stats,
            })),
            tap((pair) => {
                this.stats = pair.stats;
                this.numberOfShares = this.getMinNumberOfSharesForEntity(pair.number, this.item);
                this.numberOfSharesMin = this.item?.numSharesMin;
                this.checkNumberOfShares(this.item?.numSharesMin, this.stats?.canPurchase);

                if (this.showLimitPopupByDefault && this.showRegCFOverview) {
                    this.onUpdateLimitClick(this.stats?.regulationLimits);
                    this.showRegCfLimitModal = true;
                }
            }),
        );
    }

    getMinNumberOfSharesForEntity(number: number, entity: BazaNcIntegrationListingsDto) {
        let numberOfShares: number;

        if (entity?.numSharesMin && entity?.numSharesMin > number) {
            numberOfShares = entity.numSharesMin;
        } else {
            numberOfShares = number || entity.numSharesMin;
        }

        return numberOfShares;
    }

    getMinPurchaseAmount(entity: BazaNcIntegrationListingsDto) {
        return entity?.pricePerShareCents * entity?.numSharesMin;
    }

    checkNumberOfShares(minNumber: number, maxNumber: number): void {
        if (this.numberOfShares < minNumber) {
            this.numberOfShares = minNumber;
        } else if (this.numberOfShares > maxNumber) {
            this.numberOfShares = maxNumber;
        }
    }

    public decreaseShares(): void {
        if (this.numberOfShares - 1 >= 1) {
            this.numberOfShares--;
        }
    }

    public increaseShares(stats: StatsDto): void {
        if (this.numberOfSharesMin > +this.numberOfShares + 1) {
            this.numberOfShares = this.numberOfSharesMin;
        } else if (this.numberOfShares + 1 <= stats?.canPurchase) {
            this.numberOfShares++;
        }
    }

    public onEditPersonalInfo(): void {
        this.store.dispatch(new PatchStartPurchase({ numberOfShares: this.numberOfShares } as PurchaseFlowDto)).subscribe(() => {
            this.router.navigate([this.pfConfig.vfLink, 'info'], {
                queryParams: {
                    back: this.router.url,
                    mode: !this.pfConfig?.dwollaSupport && this.route.snapshot?.queryParams?.mode ? LEGACY_NC_MODE_QUERY_PARAM : null,
                },
            });
        });
    }

    onNextButtonClick() {
        if (this.appAndItemSupportsRegCF(this.item) && !this.regCFConsentAccepted) {
            this.showRegCfConsentModal = true;
        } else {
            this.moveToNextStep();
        }
    }

    public moveToNextStep(): void {
        const purchase: PurchaseFlowSessionDto = {
            amount: this.numberOfShares * this.item?.pricePerShareCents,
            numberOfShares: this.numberOfShares,
            offeringId: this.item?.offeringId,
            transactionType: BazaNcPurchaseFlowTransactionType.ACH,
        };

        this.store.dispatch(new StartPurchase(purchase)).subscribe(() => {
            this.router.navigate([this.pfConfig?.pfLink, 'agreement'], { queryParamsHandling: 'preserve' });
        });
    }

    public appAndItemSupportsRegCF(item: BazaNcIntegrationListingsDto) {
        return this.wts.appSupportsRegCF(this.initData) && item?.schema?.regulationType === BazaNcOfferingRegulationType.RegCF;
    }

    public get showLimitPopupByDefault() {
        return (
            this.appAndItemSupportsRegCF(this.item) &&
            this.stats?.regulationLimits?.externalRegCfInvestmentsCents === null &&
            !this.wts.isForeignInvestor(this.initData) &&
            !this.wts.isAccreditedInvestor(this.initData)
        );
    }

    public get showSharePickerError() {
        return (
            this.stats?.totalOfferingSharesRemaining === 0 ||
            this.stats?.numMinPurchaseAbleShares > this.stats?.totalOfferingSharesRemaining ||
            this.stats?.canPurchase === 0
        );
    }

    public get showRegCFOverview() {
        return (
            this.appAndItemSupportsRegCF(this.item) &&
            !this.wts.isAccreditedInvestor(this.initData) &&
            !this.wts.isForeignInvestor(this.initData) &&
            !(
                this.stats?.totalOfferingSharesRemaining === 0 ||
                this.stats?.numMinPurchaseAbleShares > this.stats?.totalOfferingSharesRemaining ||
                (this.stats?.canPurchase === 0 && this.stats?.numSharesAvailableForUser === 0)
            )
        );
    }

    public onConsentAccepted() {
        this.showRegCfConsentModal = false;
        this.regCFConsentAccepted = true;
        this.moveToNextStep();
    }

    public onLimitAccepted() {
        this.refreshStats();
        this.showRegCfLimitModal = false;
    }

    private refreshStats() {
        return this.store.dispatch([
            new ClearPurchaseStats(),
            new GetPurchaseStats(this.item?.offeringId, this.getMinPurchaseAmount(this.item)),
        ]);
    }

    onUpdateLimitClick(regCfStatLimits: PurchaseFlowInvestorRegulationLimitsDto) {
        this.store.dispatch(new ResetRegCFLimitFromStats(regCfStatLimits)).subscribe(() => {
            this.showRegCfLimitModal = true;
        });
    }
}
