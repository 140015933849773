<div
    appLinkify
    [linkConfigPath]="i18nBasePath + '.linkConfigs'">
    <!-- Account Balance -->
    <button
        *ngIf="config?.showAccountBalance && !wts.isForeignInvestor(initData)"
        class="payment-btn payment-btn-inverse payment-btn-list"
        [class.payment-btn-empty]="!wts.isDwollaAvailable(initData)"
        nz-button
        nzBlock
        [disabled]="isAccBalanceDisabled"
        (click)="onAccountBalanceSelect()">
        <div class="payment-btn__title">
            <div class="payment-btn__highlight">
                {{ wts.getI18nLabel(i18nBasePath, 'balance.title') }}
            </div>

            <ng-container [ngSwitch]="wts.isDwollaAvailable(initData)">
                <ng-container *ngSwitchCase="true">
                    <div
                        class="payment-subtitle"
                        [class.payment-subtitle-faded]="isAccBalanceDisabled">
                        <span class="payment-subtitle__prefix">
                            {{ wts.getI18nLabel(i18nBasePath, 'balance.prefix') }}
                        </span>
                        <span class="payment-subtitle__funds">
                            {{ dwollaCustomerDetailResponse?.balance?.value | priceCents }}
                        </span>
                    </div>

                    <div
                        *ngIf="isAccBalanceDisabled"
                        class="payment-subtitle payment-subtitle-error payment-subtitle-faded">
                        {{ wts.getI18nLabel(i18nBasePath, 'balance.errors.insufficientFunds') }}
                    </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <div class="payment-subtitle payment-subtitle-faded">
                        {{ wts.getI18nLabel(i18nBasePath, 'balance.hint') }}
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <div class="payment-arrow">
            <app-payment-radio
                *ngIf="showAccBalanceRadioBtn"
                [isSelected]="selectedPaymentMethod === PAYMENT_METHOD_TYPE.accountBalance"
                [isDisabled]="isAccBalanceDisabled"></app-payment-radio>

            <i
                *ngIf="!wts.isDwollaAvailable(initData)"
                class="icon icon-angle-right icon-wt payment-btn__header-icon"></i>
        </div>
    </button>

    <!-- NC ACH Bank Account -->
    <button
        *ngIf="config?.showBankAccount"
        class="payment-btn payment-btn-inverse payment-btn-list"
        nz-button
        nzBlock
        (click)="onBankAccountSelect()"
        [class.payment-btn-inactive]="wts.isNCBankAccountAvailable(initData) && !showACHRadioBtn"
        [class.payment-btn-empty]="!wts.isNCBankAccountAvailable(initData)">
        <div class="payment-btn__title">
            <div [class.payment-btn__highlight]="wts.isNCBankAccountAvailable(initData)">
                {{ wts.getI18nLabel(i18nBasePath, 'bank.title') }}

                <ng-container *ngIf="!wts.isNCBankAccountAvailable(initData) && wts.getI18nLabel(i18nBasePath, 'bank.subtitle')">
                    <div class="payment-subtitle payment-subtitle-faded">
                        {{ wts.getI18nLabel(i18nBasePath, 'bank.subtitle') }}
                    </div>
                </ng-container>
            </div>

            <ng-container *ngIf="wts.isNCBankAccountAvailable(initData)">
                <div class="payment-subtitle">
                    {{ bankAccountResponse?.details?.accountNumber | lastDigits : 8 }}, {{ bankAccountResponse?.details?.accountType }}
                </div>

                <div class="payment-subtitle">
                    <a
                        (click)="onBankAccountUpdate($event)"
                        class="payment-subtitle__link">
                        {{ wts.getI18nLabel(i18nBasePath, 'bank.actions.update') }}
                    </a>
                </div>
            </ng-container>
        </div>

        <div class="payment-arrow">
            <app-payment-radio
                *ngIf="showACHRadioBtn"
                [isSelected]="selectedPaymentMethod === PAYMENT_METHOD_TYPE.bankAccount"></app-payment-radio>

            <i
                *ngIf="!wts.isNCBankAccountAvailable(initData)"
                class="icon icon-angle-right icon-wt payment-btn__header-icon"></i>
        </div>
    </button>

    <!-- Credit Card -->
    <ng-container *ngIf="config?.showCreditCard">
        <button
            class="payment-btn payment-btn-inverse payment-btn-list"
            nz-button
            nzBlock
            [disabled]="isPurchaseAboveLimit"
            [class.payment-btn-fluid]="isPurchaseAboveLimit"
            [class.payment-btn-inactive]="wts.isCardAvailable(initData) && !showCCRadioBtn"
            [class.payment-btn-empty]="!wts.isCardAvailable(initData)"
            *ngIf="limits$ | async as limits"
            (click)="onCreditCardSelect()">
            <div class="payment-btn__title">
                <div [class.payment-btn__highlight]="wts.isCardAvailable(initData)">
                    {{ wts.getI18nLabel(i18nBasePath, 'card.title') }}
                </div>

                <ng-container *ngIf="!wts.isCardAvailable(initData)">
                    <div class="payment-subtitle payment-subtitle-faded">
                        <ng-container *ngIf="wts.getI18nLabel(i18nBasePath, 'card.feePrefix')">
                            {{ wts.getI18nLabel(i18nBasePath, 'card.feePrefix') }}
                        </ng-container>
                        + {{ limits.creditCardPaymentsFee | numberTwoDigits }}%
                        {{ wts.getI18nLabel(i18nBasePath, 'card.feeSuffix') }}
                    </div>
                </ng-container>

                <ng-container *ngIf="wts.isCardAvailable(initData)">
                    <div class="payment-subtitle">
                        <span class="payment-subtitle__prefix">
                            {{ creditCardResponse?.card?.creditCardNumber | lastDigits : 8 }}
                        </span>
                        <span class="payment-subtitle-faded">
                            +{{ limits.creditCardPaymentsFee | numberTwoDigits }}% {{ wts.getI18nLabel(i18nBasePath, 'card.feeSuffix') }}
                        </span>
                    </div>
                    <div
                        class="payment-subtitle"
                        *ngIf="!isPurchaseAboveLimit">
                        <a
                            (click)="onDeleteCreditCard($event)"
                            class="payment-subtitle__link">
                            {{ wts.getI18nLabel(i18nBasePath, 'card.actions.update') }}
                        </a>
                    </div>
                </ng-container>
            </div>

            <div
                class="payment-arrow"
                [class.payment-arrow-centered]="isPurchaseAboveLimit">
                <app-payment-radio
                    *ngIf="showCCRadioBtn"
                    [isSelected]="selectedPaymentMethod === PAYMENT_METHOD_TYPE.creditCard"
                    [isDisabled]="isPurchaseAboveLimit"></app-payment-radio>

                <ng-container *ngIf="!wts.isCardAvailable(initData)">
                    <i class="icon icon-angle-right icon-wt payment-btn__header-icon"></i>
                </ng-container>
            </div>

            <div
                class="payment-btn__descr"
                *ngIf="isPurchaseAboveLimit"
                [ngClass]="{ 'payment-btn__descr-error': isPurchaseAboveLimit }">
                {{
                    wts.getI18nLabel(i18nBasePath, 'card.limitsWarning', {
                        maxAmount: limits.maxCreditCardTransferAmountCents / 100 | priceCents | slice : 0 : -3
                    })
                }}
            </div>
        </button>

        <app-confirm-modal
            *ngIf="deleteCCConfirmVisible"
            [confirmBtnDanger]="false"
            (confirm)="handleDelete()"
            (cancel)="deleteCCConfirmVisible = false"
            [cancelBtnText]="wts.getI18nLabel(i18nBasePath, 'card.alerts.confirmUpdate.cancelBtnText')"
            [confirmBtnText]="wts.getI18nLabel(i18nBasePath, 'card.alerts.confirmUpdate.confirmBtnText')">
            <ng-container modalTitle>
                <span [innerHtml]="wts.getI18nLabel(i18nBasePath, 'card.alerts.confirmUpdate.title')"></span>
            </ng-container>

            <ng-container modalContent>
                <p
                    class="ant-modal__descr"
                    [innerHtml]="wts.getI18nLabel(i18nBasePath, 'card.alerts.confirmUpdate.descr')"></p>
            </ng-container>
        </app-confirm-modal>
    </ng-container>
</div>
