import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { CrudSortableUlidEntity } from '../entities/crud-sortable-ulid-entity';

export class CrudSetSortOrderByUlidResponseDto<T extends CrudSortableUlidEntity> {
    @ApiModelProperty({
        description: 'Entity which was sort ordered',
        type: () => Object,
    })
    entity: T;

    @ApiModelProperty({
        description: 'Affected entities',
        type: () => [Object],
    })
    affected: Array<T>;
}
