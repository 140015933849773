import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { DividendAlertState } from '@scaliolabs/web/data-access';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-dividend-alert',
    templateUrl: './dividend-alert.component.html',
    styleUrls: ['./dividend-alert.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DividendAlertComponent {
    @Select(DividendAlertState.show)
    showAlert$: Observable<boolean>;
}
