import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { BazaNcDataAccessModule } from '@scaliolabs/baza-nc-data-access';

import { TaxDocumentState } from './state';

@NgModule({
    imports: [BazaNcDataAccessModule, NgxsModule.forFeature([TaxDocumentState])],
})
export class TaxDocumentStateModule {}
