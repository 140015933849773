import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
    ConfirmModalComponent,
    IframeIntegrationComponent,
    PaymentHeaderComponent,
    PaymentRadioComponent,
} from '@scaliolabs/baza-web-ui-components';
import { UtilModule } from '@scaliolabs/baza-web-utils';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { PaymentEditAddComponent } from './add/add.component';
import { PaymentEditListComponent } from './list/list.component';
import { PaymentEditComponent } from './payment-edit.component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

@NgModule({
    declarations: [PaymentEditComponent, PaymentEditAddComponent, PaymentEditListComponent],
    exports: [PaymentEditComponent, PaymentEditAddComponent, PaymentEditListComponent],
    imports: [
        CommonModule,
        NzButtonModule,
        NzModalModule,
        NzSkeletonModule,
        PaymentHeaderComponent,
        PaymentRadioComponent,
        RouterModule,
        UtilModule,
        IframeIntegrationComponent,
        ConfirmModalComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PaymentEditModule {}
