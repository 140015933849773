import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UtilModule } from '@scaliolabs/baza-web-utils';
import { NzButtonModule } from 'ng-zorro-antd/button';

import { FavoriteButtonComponent } from './favorite-btn.component';

@NgModule({
    declarations: [FavoriteButtonComponent],
    exports: [FavoriteButtonComponent],
    imports: [CommonModule, NzButtonModule, RouterModule, UtilModule],
})
export class FavoriteButtonModule {}
