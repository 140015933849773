<div
    class="cta"
    appLinkify
    [linkConfigPath]="'uic.marketing.cta.linkConfigs'">
    <div class="container cta__container">
        <div class="cta__wrap">
            <div
                class="cta__heading"
                *ngIf="header"
                [innerHtml]="header | sanitizeHtml"></div>

            <div
                class="cta__descr"
                *ngIf="description"
                [innerHtml]="description | sanitizeHtml"></div>

            <div class="cta__btn">
                <button
                    nz-button
                    [class]="btnClass"
                    [innerHtml]="wts.getI18nLabel(i18nBasePath, 'btnCTA.label') | sanitizeHtml"></button>
            </div>
        </div>
    </div>
</div>
