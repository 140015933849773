import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BazaNcBootstrapDataAccess } from '@scaliolabs/baza-nc-data-access';
import { BazaNcBootstrapDto } from '@scaliolabs/baza-nc-shared';
import { EffectsUtil } from '@scaliolabs/baza-web-utils';
import { tap } from 'rxjs/operators';
import { RequestAppBootstrapInitData } from './actions';

export interface BootstrapStateModel {
    initData: BazaNcBootstrapDto | null;
}

const initState = {
    name: 'bootstrap',
    defaults: {
        initData: null,
    },
};

@State<BootstrapStateModel>(initState)
@Injectable()
export class BootstrapState {
    constructor(private readonly dataAccess: BazaNcBootstrapDataAccess, private readonly effectsUtil: EffectsUtil) {}

    @Selector()
    static initData(state: BootstrapStateModel): BazaNcBootstrapDto {
        return state.initData;
    }

    @Action(RequestAppBootstrapInitData, { cancelUncompleted: true })
    requestAppBootstrapInitData(ctx: StateContext<BootstrapStateModel>) {
        return this.dataAccess.bootstrap().pipe(
            tap((response: BazaNcBootstrapDto) => {
                ctx.patchState({ initData: response });
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }
}
