export const PayoutBankEnI18n = {
    title: 'Bank Account',
    hint: 'Used to withdraw funds from your wallet',
    alerts: {
        addPayoutMethod: 'Add a bank account in your Payout Method to receive dividends',
        withdrawalSupport: {
            label: 'Reach out to us at <a data-link="withdrawalLink"></a> to get instructions on withdrawal.',
        },
        linkBank: 'You need to have linked bank account to withdraw from your balance.',
    },
    actions: {
        update: 'Update',
    },
    linkConfigs: [
        {
            key: 'withdrawalLink',
            link: 'bazaContentTypes.contacts.email',
            isCMSLink: true,
            isMailLink: true,
        },
    ],
};
