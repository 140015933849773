import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BazaNcIntegrationListingsDto } from '@scaliolabs/baza-nc-integration-shared';
import { PurchaseFlowDto, PurchaseFlowSessionDto } from '@scaliolabs/baza-nc-shared';
import { IframeIntegrationComponent } from '@scaliolabs/baza-web-ui-components';
import { BazaWebUtilSharedService } from '@scaliolabs/baza-web-utils';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DocuSignEvents, PatchStartPurchase, PurchaseState, StartPurchase } from '../data-access';
import { EMPTY, switchMap } from 'rxjs';
import { BazaLoadingService } from '@scaliolabs/baza-core-ng';

@UntilDestroy()
@Component({
    selector: 'app-purchase-agreement',
    templateUrl: './agreement.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseAgreementComponent {
    purchaseStart$ = this.store.select(PurchaseState.purchaseStart);
    cart$ = this.store.select(PurchaseState.cart);
    numberOfShares$ = this.store.select(PurchaseState.numberOfShares);
    i18nBasePath = 'dwpf.agreement';

    constructor(
        private readonly modalService: NzModalService,
        private readonly router: Router,
        private readonly store: Store,
        public readonly wts: BazaWebUtilSharedService,
        public readonly loader: BazaLoadingService,
    ) {}

    public openAgreementModal(entity: BazaNcIntegrationListingsDto, purchaseStart: PurchaseFlowDto) {
        this.refreshSession(entity, purchaseStart, () => {
            this.loadAgreementModal();
        });
    }

    refreshSession(entity: BazaNcIntegrationListingsDto, purchaseStart: PurchaseFlowDto, onSuccess) {
        const purchase: PurchaseFlowSessionDto = {
            offeringId: entity.offeringId,
            numberOfShares: purchaseStart.numberOfShares,
            amount: purchaseStart.amount,
            requestDocuSignUrl: true,
        };

        this.store.dispatch(new StartPurchase(purchase)).subscribe(() => {
            onSuccess();
        });
    }

    loadAgreementModal() {
        const purchaseStart = this.store.selectSnapshot(PurchaseState.purchaseStart);
        const modalRef = this.modalService.create({
            nzClassName: 'ant-modal-transparent',
            nzClosable: false,
            nzMaskClosable: false,
            nzKeyboard: false,
            nzContent: IframeIntegrationComponent,
            nzComponentParams: {
                url: purchaseStart?.docuSignUrl,
                events: [DocuSignEvents.SigningComplete, DocuSignEvents.Decline, DocuSignEvents.Cancel],
                classList: 'iframe-docusign',
            } as Partial<IframeIntegrationComponent<DocuSignEvents>>,
            nzFooter: null,
            nzWidth: 1030,
        });

        modalRef?.componentInstance?.iframeEvent
            .pipe(
                untilDestroyed(this),
                switchMap((currentDocusignLastEvent) => {
                    modalRef.close();

                    if (currentDocusignLastEvent === DocuSignEvents.SigningComplete) {
                        purchaseStart.areDocumentsSigned = true;
                        return this.store.dispatch(new PatchStartPurchase(purchaseStart));
                    }

                    return EMPTY;
                }),
            )
            .subscribe(() => {
                this.navigateToStep('payment');
            });
    }

    public onFormSubmit(): void {
        this.navigateToStep('payment');
    }

    public onBackClick(purchaseStart: PurchaseFlowDto): void {
        purchaseStart.fee = null;
        this.store.dispatch(new PatchStartPurchase(purchaseStart)).subscribe(() => {
            this.navigateToStep('details');
        });
    }

    navigateToStep(url: string) {
        this.router.navigate([this.store.selectSnapshot(PurchaseState.pfConfig)?.pfLink, url], {
            queryParamsHandling: 'preserve',
        });
    }
}
