import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Account, AccountState } from '@scaliolabs/web/data-access';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'app-footer-menu',
    templateUrl: './footer-menu.component.html',
    styleUrls: ['./footer-menu.component.less'],
})
export class FooterMenuComponent {
    @Select(AccountState.account)
    user$: Observable<Account | null>;

    public user: Account;

    constructor() {
        this.user$.pipe(untilDestroyed(this)).subscribe((user) => (this.user = user));
    }
}
