import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { UtilModule } from '@scaliolabs/baza-web-utils';
import { DividendAlertStateModule } from '@scaliolabs/web/data-access';

import { DividendAlertComponent } from './dividend-alert.component';

@NgModule({
    declarations: [DividendAlertComponent],
    exports: [DividendAlertComponent],
    imports: [CommonModule, NzAlertModule, NzButtonModule, RouterModule, UtilModule, DividendAlertStateModule],
})
export class DividendAlertModule {}
