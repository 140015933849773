export const WebUiMarketingHeroEnI18n = {
    section0: {
        btnCTA: {
            label: `<a data-link="heroCTALink"></a>`,
        },
    },
    linkConfigs: [
        {
            key: `heroCTALink`,
            commands: ['', { outlets: { modal: ['auth', 'signup'] } }],
            navExtras: {
                queryParams: {
                    redirectUrl: '/items',
                },
                state: { skipScroll: true },
            },
            text: `Get Started`,
        },
    ],
};
