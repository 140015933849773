<ul class="footer-social">
    <li
        class="footer-social__icon"
        *ngFor="let socialMedia of getSocialMedia() | keyvalue">
        <a
            href="{{ socialMedia.value }}"
            target="_blank">
            <i class="icon icon-social-{{ socialMedia.key | lowercase }}"></i>
        </a>
    </li>
</ul>
