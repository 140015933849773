import { Injectable, NgZone } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { LoaderService } from '../loader/loader.service';

// Effects Util
@Injectable({ providedIn: 'root' })
export class EffectsUtil {
    constructor(
        private readonly loader: LoaderService,
        private readonly ngZone: NgZone,
        private readonly notification: NzNotificationService,
    ) {}

    // if errorMessage is empty, then BE error will be used
    tryCatch$ =
        <T>(successMessage: string, errorMessage: string, showGlobalLoader = true) =>
        ($: Observable<T>) => {
            this.loader.show(showGlobalLoader);

            return $.pipe(
                this.successFailNotify$(successMessage, errorMessage),
                finalize(() => {
                    this.ngZone.run(() => this.loader.hide(showGlobalLoader));
                }),
            );
        };

    tryCatchNoRethrow$ =
        <T>(successMessage: string, errorMessage: string, showGlobalLoader = true) =>
        ($: Observable<T>) => {
            this.loader.show(showGlobalLoader);

            return $.pipe(
                tap(() => successMessage && this.notification.success(successMessage, '')),
                catchError((err) => {
                    if (errorMessage === '' && err.statusCode !== 401) {
                        this.notification.error(err.message, '');
                    }
                    if (errorMessage && err.statusCode !== 401) {
                        this.notification.error(errorMessage, '');
                    }
                    return EMPTY;
                }),
                finalize(() => {
                    this.ngZone.run(() => this.loader.hide(showGlobalLoader));
                }),
            );
        };

    // if errorMessage is empty, then BE error will be used
    tryCatchError$ =
        <T>(errorMessage: string, showGlobalLoader = true) =>
        ($: Observable<T>) => {
            this.loader.show(showGlobalLoader);

            return $.pipe(
                this.successFailNotify$(undefined, errorMessage),
                finalize(() => {
                    this.ngZone.run(() => this.loader.hide(showGlobalLoader));
                }),
            );
        };

    // catch rethrown errors in custom catchError blocks and notify
    tryCatchRethrownErrorNotify$ =
        <T>(errorMessage: string, showGlobalLoader = true) =>
        ($: Observable<T>) => {
            return $.pipe(
                catchError((e) => {
                    if ((e['rethrown'] ?? false) === false) {
                        this.notification.error(errorMessage, '');
                    }
                    return EMPTY;
                }),
            );
        };

    tryCatchNone$ =
        <T>(showGlobalLoader = true) =>
        ($: Observable<T>) => {
            this.loader.show(showGlobalLoader);

            return $.pipe(
                this.successFailNotify$(undefined, undefined),
                finalize(() => {
                    this.ngZone.run(() => this.loader.hide(showGlobalLoader));
                }),
            );
        };

    tryCatchSuccess$ =
        <T>(successMessage: string, showGlobalLoader = true) =>
        ($: Observable<T>) => {
            this.loader.show(showGlobalLoader);

            return $.pipe(
                this.successFailNotify$(successMessage, undefined),
                finalize(() => {
                    this.ngZone.run(() => this.loader.hide(showGlobalLoader));
                }),
            );
        };

    successFailNotify$ =
        <T>(successMessage: string | undefined, errorMessage: string | undefined) =>
        ($: Observable<T>) =>
            $.pipe(
                tap(() => successMessage && this.notification.success(successMessage, '')),
                catchError((err) => {
                    if (errorMessage === '' && err.statusCode !== 401) {
                        this.notification.error(err.message, '');
                    }
                    if (errorMessage && err.statusCode !== 401) {
                        this.notification.error(errorMessage, '');
                    }

                    // Dynamic property added to identify rethrown errors in custom RxJS data streams
                    err['rethrown'] = true;

                    throw err;
                }),
            );
}
