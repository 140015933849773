import { NavigationExtras } from '@angular/router';

export interface LinkConfig {
    key?: string;
    link?: string;
    isCMSLink?: boolean;
    isMailLink?: boolean;
    text?: string;
    target?: LinkTargetEnum;

    // specific for hard-coded internal links
    commands?: unknown[];
    navExtras?: NavigationExtras;
}

export enum LinkTargetEnum {
    self = '_self',
    blank = '_blank',
}
