<section class="payment-block">
    <ng-container *ngTemplateOutlet="header"></ng-container>
    <ng-container
        *ngIf="{
            bankAccount: bankAccount$ | async,
            creditCard: creditCard$ | async,
            limits: limits$ | async,
            cashInAccount: dwollaDefaultCashInAccount$ | async,
            dwollaCustomerDetail: dwollaCustomerDetail$ | async
        } as data">
        <div
            *ngIf="config?.accountBalanceConfig?.showAccountBalance && !wts.isForeignInvestor(initData)"
            nz-row
            class="payment-block__row payment-block__row-wallet">
            <div
                nz-col
                nzXs="12"
                class="payment-block__col">
                <ng-container *ngIf="data.dwollaCustomerDetail && data.cashInAccount; else skeleton">
                    <app-account-balance
                        [initData]="initData"
                        [dwollaCustomerDetail]="data?.dwollaCustomerDetail"
                        [dwollaDefaultCashInAccount]="data?.cashInAccount"
                        [enableTopup]="config?.accountBalanceConfig?.enableTopup"
                        (refreshAccountBalanceData)="onRefreshAccountBalanceData()"></app-account-balance>
                </ng-container>
            </div>
        </div>

        <div
            *ngIf="config?.bankConfig?.showBankAccount"
            nz-row
            class="payment-block__row payment-block__row-bank">
            <div
                *ngIf="config?.bankConfig?.sectionHeader"
                nz-col
                nzXs="12"
                class="payment-block__col">
                <div class="payment-block__heading">
                    {{ config?.bankConfig?.sectionHeader }}
                </div>
            </div>

            <div
                nz-col
                nzXs="12"
                class="payment-block__col">
                <ng-container *ngIf="data.bankAccount; else skeleton">
                    <app-payment-item-bank
                        [initData]="initData"
                        [bankAccountResponse]="data?.bankAccount"
                        (upsertBankAccount)="onUpsertBankAccount()"></app-payment-item-bank>
                </ng-container>
            </div>
        </div>

        <div
            *ngIf="config?.cardConfig?.showCreditCard"
            nz-row
            class="payment-block__row payment-block__row-card">
            <div
                *ngIf="config?.cardConfig?.sectionHeader"
                nz-col
                nzXs="12"
                class="payment-block__col">
                <div class="payment-block__heading">
                    {{ config?.cardConfig?.sectionHeader }}
                </div>
            </div>

            <div
                nz-col
                nzXs="12"
                class="payment-block__col">
                <ng-container *ngIf="data.creditCard; else skeleton">
                    <app-payment-item-card
                        [initData]="initData"
                        [creditCardResponse]="data?.creditCard"
                        [limits]="data?.limits"
                        (addCreditCard)="onAddCreditCard($event)"
                        (unlinkCreditCard)="onUnlinkCreditCard($event)"></app-payment-item-card>
                </ng-container>
            </div>
        </div>
    </ng-container>
</section>

<ng-template #skeleton>
    <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>
