<nz-alert
    *ngIf="showAlert$ | async"
    class="dividend-alert"
    [nzShowIcon]="false"
    [nzBanner]="true"
    [nzMessage]="alertMsg"></nz-alert>

<ng-template #alertMsg>
    Add a bank account in your Payout Method to receive dividends.

    <a [routerLink]="['/account', 'payment-method']">Add Account</a>
</ng-template>
