import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';

import { BazaNcIntegrationFeedPostType } from '@scaliolabs/baza-nc-integration-shared';
import { UtilModule } from '@scaliolabs/baza-web-utils';
import { FeedPost } from '@scaliolabs/web/data-access';

@Component({
    selector: 'app-news-card',
    templateUrl: './news-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, NzButtonModule, NzCardModule, RouterModule, UtilModule],
})
export class NewsCardComponent {
    @Input()
    post?: FeedPost;

    @Input()
    isExpanded = false;

    @Output()
    applause = new EventEmitter<void>();

    @Output()
    expand = new EventEmitter<void>();

    @ViewChild('socialNetworks') socialNetworks: TemplateRef<void>;

    innerWidth: number;
    POST_TYPE = BazaNcIntegrationFeedPostType;

    constructor(private readonly sanitizer: DomSanitizer) {
        this.innerWidth = window?.innerWidth;
    }

    public get bgImg() {
        let imgUrl = null;

        if (!this.post?.isLocked) {
            if (this.post?.type === BazaNcIntegrationFeedPostType.Video)
                imgUrl = this.post?.previewImageUrl
                    ? this.sanitizer.bypassSecurityTrustStyle(`url(${this.post?.previewImageUrl})`)
                    : this.sanitizer.bypassSecurityTrustStyle(`url('/assets/images/video-bg.jpg')`);
            else if (this.post?.type === BazaNcIntegrationFeedPostType.Image || this.post?.type === BazaNcIntegrationFeedPostType.Article)
                imgUrl = this.sanitizer.bypassSecurityTrustStyle(`url(${this.post?.previewImageUrl})`);
        }

        return imgUrl;
    }
}
