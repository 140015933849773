<nz-layout [ngClass]="{ 'ant-layout-alert': showAlert$ | async }">
    <app-header
        [(openedMenu)]="openedMenu"
        *ngIf="layout === 'full'"></app-header>

    <nz-content
        [ngClass]="{
            'ant-layout-content-mini': layout === 'mini',
            'ant-layout-content-bg': layoutParams.bg,
            'ant-layout-content-center': layoutParams.center,
            'ant-layout-content-screen': layoutParams.screen
        }">
        <div [ngClass]="{ 'ant-layout-wrapper': layoutParams.wrapper }">
            <router-outlet></router-outlet>
        </div>
    </nz-content>

    <app-footer *ngIf="layout === 'full'"></app-footer>

    <app-footer-mini *ngIf="layout === 'mini'"></app-footer-mini>
</nz-layout>
