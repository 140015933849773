<form
    class="ant-form-inverse"
    nz-form
    nzLayout="vertical"
    [formGroup]="withdrawFundsForm"
    #withdrawFundsFormEl
    appLinkify
    [linkConfigPath]="i18nBasePath + '.linkConfigs'">
    <div class="payment-funds-form">
        <nz-form-item *ngIf="withdrawFundsError">
            <nz-alert
                class="payment-funds__alert payment-funds__alert-error"
                data-cy="withdraw-form-error"
                [nzMessage]="withdrawAmountError"
                [nzType]="withdrawFundsError?.type"></nz-alert>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label
                nzFor="withdrawAmount"
                [innerHTML]="wts.getI18nLabel(i18nFormFieldsPath, 'amount.label') | sanitizeHtml"></nz-form-label>

            <nz-form-control [nzErrorTip]="withdrawAmountErrorTemplate">
                <nz-input-number
                    class="payment-funds__input"
                    formControlName="withdrawAmount"
                    id="withdrawAmount"
                    name="withdrawAmount"
                    type="number"
                    [nzPrecisionMode]="amountConfig.precisionMode"
                    [nzMin]="0.01"
                    [nzMax]="walletAmount < 10000 ? walletAmount : 10000"
                    [nzStep]="0.01"
                    [nzPrecision]="amountConfig.precision"
                    [nzFormatter]="wts.dollarFormat"
                    [nzParser]="wts.dollarParse"
                    appAmountMask
                    [maxLength]="amountValidationMaxLength"></nz-input-number>
            </nz-form-control>
            <ng-template
                #withdrawAmountErrorTemplate
                let-control>
                <span data-cy="withdraw-form-input-error">
                    {{ getErrorMessage(control, 'amount') }}
                </span>
            </ng-template>
        </nz-form-item>
    </div>
</form>

<ng-template #withdrawAmountError>
    <ng-container *ngIf="withdrawFundsError">
        <p [innerHtml]="withdrawFundsError?.text | sanitizeHtml"></p>
    </ng-container>
</ng-template>
