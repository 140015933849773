import { Injectable } from '@angular/core';
import { Action, State, Selector, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { MktPagesDataAccess } from '@scaliolabs/baza-content-types-data-access';
import { MktPageDto } from '@scaliolabs/baza-content-types-shared';
import { EffectsUtil } from '@scaliolabs/baza-web-utils';

import { GetSections } from './actions';

export interface MarketingStateModel {
    items: MktPageDto[];
}

@State<MarketingStateModel>({
    name: 'marketing',
    defaults: {
        items: [],
    },
})
@Injectable()
export class MarketingState {
    @Selector()
    static sections(state: MarketingStateModel): MktPageDto[] {
        return state ? state.items : [];
    }

    @Selector()
    static investSections(state: MarketingStateModel): MktPageDto[] {
        return state ? state.items.filter((el) => el.section.toLowerCase().includes('invest-block')) : [];
    }

    @Selector()
    static hiwSections(state: MarketingStateModel): MktPageDto[] {
        return state ? state.items.filter((el) => el.section.toLowerCase().includes('how-it-works-block')) : [];
    }

    constructor(private readonly effectsUtil: EffectsUtil, private readonly marketingService: MktPagesDataAccess) {}

    @Action(GetSections, { cancelUncompleted: true })
    getSections(ctx: StateContext<MarketingStateModel>) {
        return this.marketingService.get().pipe(
            tap((response: MktPageDto[]) => {
                ctx.patchState({ items: response });
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }
}
