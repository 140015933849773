import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BazaFormValidatorService, BazaLoadingService } from '@scaliolabs/baza-core-ng';
import { BazaNcBankAccountGetDefaultByTypeResponse } from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService, Message, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { DwollaAddFundsFormComponent } from '../add-funds-form/add-funds-form.component';

@Component({
    selector: 'app-add-funds-modal',
    templateUrl: './add-funds-modal.component.html',
    standalone: true,
    imports: [CommonModule, UtilModule, NzModalModule, NzButtonModule, DwollaAddFundsFormComponent],
})
export class DwollaAddFundsModalComponent {
    @Input()
    addFundsForm: UntypedFormGroup;

    @Input()
    dwollaDefaultCashInAccount: BazaNcBankAccountGetDefaultByTypeResponse;

    @Input()
    addFundsError: Message;

    @Output()
    handleAddFundsCancel = new EventEmitter();

    @Output()
    handleEditCashInAccount = new EventEmitter();

    @Output()
    submitAddFundsForm = new EventEmitter();

    @ViewChild('addFundsFormComp') addFundsFormCompRef: DwollaAddFundsFormComponent;

    i18nBasePath = 'uic.addFunds.modal';

    constructor(
        public readonly bazaFormValidatorService: BazaFormValidatorService,
        public readonly wts: BazaWebUtilSharedService,
        public readonly loader: BazaLoadingService,
    ) {}

    onFormSubmit(modalEl = null): void {
        const formElRef = this.addFundsFormCompRef?.addFundsFormRef?.nativeElement ?? null;

        if (this.bazaFormValidatorService.isFormValid(this.addFundsForm, formElRef, modalEl)) {
            this.submitAddFundsForm.emit();
        }
    }
}
