export const WebUiMarketingBannerEnI18n = {
    section0: {
        btnCTA: {
            label: `<a data-link="bannerCTALink"></a>`,
        },
    },
    linkConfigs: [
        {
            key: `bannerCTALink`,
            commands: ['/items'],
            text: 'View Offering',
        },
    ],
};
