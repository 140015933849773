<div
    *ngIf="!wts.isForeignInvestor(initData)"
    appLinkify
    [linkConfigPath]="i18nBasePath + '.linkConfigs'">
    <nz-alert
        *ngIf="dss.showDwollaAccountCreationError$ | async"
        class="payment-block__alert"
        nzType="error"
        data-cy="dwolla-available-error"
        [nzMessage]="dwollaAvailableError"></nz-alert>

    <div
        class="payment-collapse"
        *ngIf="wts.isDwollaAvailable(initData); else accountBalanceItem">
        <nz-collapse [nzBordered]="false">
            <nz-collapse-panel
                class="payment-collapse__panel"
                [nzActive]="isPanelOpen"
                [nzHeader]="accountBalanceItem">
                <ng-template
                    *ngIf="enableTopup && wts.isDwollaCashInAccountLinked(initData)"
                    [ngTemplateOutlet]="bankAccountItem"></ng-template>
            </nz-collapse-panel>
        </nz-collapse>
    </div>

    <ng-template #accountBalanceItem>
        <app-payment-item
            [isPaymentLinked]="wts.isDwollaAvailable(initData)"
            [isDisabled]="dss.showDwollaAccountCreationError$ | async"
            (clickContainer)="onAccountBalanceClicked()">
            <app-payment-item-header>
                {{ wts.getI18nLabel(i18nBasePath, 'title') }}
            </app-payment-item-header>

            <app-payment-item-detail *ngIf="wts.isDwollaAvailable(initData); else fundYourAccount">
                <span class="payment-subtitle">
                    <span
                        class="payment-subtitle__prefix"
                        *ngIf="wts.getI18nLabel(i18nBasePath, 'details.prefix') !== ''">
                        {{ wts.getI18nLabel(i18nBasePath, 'details.prefix') }}
                    </span>
                    <span class="payment-subtitle__funds">
                        {{ dwollaCustomerDetail?.balance?.value | priceCents }}
                    </span>
                </span>
            </app-payment-item-detail>

            <ng-template #fundYourAccount>
                <app-payment-item-detail>
                    <span class="payment-subtitle payment-subtitle-faded">
                        {{ wts.getI18nLabel(i18nBasePath, 'details.hint') }}
                    </span>
                </app-payment-item-detail>
            </ng-template>

            <app-payment-item-actions *ngIf="enableTopup">
                <button
                    nz-button
                    nzSize="small"
                    type="button"
                    nzType="primary"
                    (click)="onAddFundsClicked()">
                    {{ wts.getI18nLabel(i18nBasePath, 'actions.addFunds') }}
                </button>

                <button
                    *ngIf="wts.isDwollaCashInAccountLinked(initData)"
                    class="payment-item-collapse__toggle"
                    nz-button
                    nzSize="small"
                    type="button"
                    (click)="isPanelOpen = !isPanelOpen">
                    <span class="icon icon-down"></span>
                </button>
            </app-payment-item-actions>
        </app-payment-item>
    </ng-template>

    <ng-template #bankAccountItem>
        <ng-container *ngIf="enableTopup && isPanelOpen">
            <app-payment-item [isPaymentLinked]="wts.isDwollaAvailable(initData)">
                <app-payment-item-header>
                    {{ wts.getI18nLabel(i18nBasePath, 'linkedBank.title') }}

                    <span class="payment-subtitle payment-subtitle-faded payment-subtitle-hint">
                        {{ wts.getI18nLabel(i18nBasePath, 'linkedBank.hint') }}
                    </span>
                </app-payment-item-header>

                <app-payment-item-detail>{{ dwollaDefaultCashInAccount?.result?.accountName }}</app-payment-item-detail>
                <app-payment-item-detail>{{ dwollaDefaultCashInAccount?.result?.accountType }}</app-payment-item-detail>

                <app-payment-item-actions>
                    <button
                        nz-button
                        nzSize="small"
                        type="button"
                        (click)="updateCashInAccount()">
                        {{ wts.getI18nLabel(i18nBasePath, 'linkedBank.actions.update') }}
                    </button>
                </app-payment-item-actions>
            </app-payment-item>
        </ng-container>
    </ng-template>

    <ng-template #dwollaAvailableError>
        <div [innerHTML]="wts.getI18nLabel(i18nBasePath, 'alerts.walletSupport.label') | sanitizeHtml"></div>
    </ng-template>

    <div
        class="payment-funds payment-funds-add"
        *ngIf="enableTopup">
        <app-add-funds-modal
            *ngIf="isAddFundsFormVisible"
            [addFundsForm]="addFundsForm"
            [dwollaDefaultCashInAccount]="dwollaDefaultCashInAccount"
            [addFundsError]="addFundsError$ | async"
            (handleAddFundsCancel)="handleAddFundsCancel()"
            (handleEditCashInAccount)="handleEditCashInAccount()"
            (submitAddFundsForm)="submitAddFundsForm()"></app-add-funds-modal>

        <app-add-funds-notification
            *ngIf="isAddFundsNotificationVisible"
            (closeModal)="isAddFundsNotificationVisible = false"></app-add-funds-notification>
    </div>
</div>
