import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { BazaNcIntegrationPortfolioTransactionType } from '../models/baza-nc-integration-portfolio-transaction-type';
import { BazaNcIntegrationPortfolioTransactionInvestmentEntityDto } from './baza-nc-integration-portfolio-transaction-investment-entity.dto';
import { Type } from 'class-transformer';

export class BazaNcIntegrationPortfolioTransactionInvestmentDto {
    @ApiModelProperty({
        type: 'string',
        enum: [BazaNcIntegrationPortfolioTransactionType.Investment],
    })
    type: BazaNcIntegrationPortfolioTransactionType.Investment;

    @ApiModelProperty({
        description: 'Date',
        example: new Date().toISOString(),
    })
    date: string;

    @ApiModelProperty()
    @Type(() => BazaNcIntegrationPortfolioTransactionInvestmentEntityDto)
    entity: BazaNcIntegrationPortfolioTransactionInvestmentEntityDto;
}
