import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { UtilModule } from '@scaliolabs/baza-web-utils';

import { ShareButtonComponent } from './share-btn.component';

@NgModule({
    declarations: [ShareButtonComponent],
    exports: [ShareButtonComponent],
    imports: [CommonModule, RouterModule, NzButtonModule, UtilModule],
})
export class ShareButtonModule {}
