import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { UtilModule } from '@scaliolabs/baza-web-utils';
import { FeedStateModule } from '@scaliolabs/web/data-access';

import { DividendAlertModule } from '../dividend-alert';
import { HeaderComponent } from './header.component';

@NgModule({
    declarations: [HeaderComponent],
    exports: [HeaderComponent],
    imports: [
        CommonModule,
        DividendAlertModule,
        FeedStateModule,
        NzButtonModule,
        NzGridModule,
        NzLayoutModule,
        NzMenuModule,
        RouterModule,
        UtilModule,
    ],
})
export class HeaderModule {}
