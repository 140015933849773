import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { BazaAccountDataAccess } from '@scaliolabs/baza-core-data-access';
import { EffectsUtil } from '@scaliolabs/baza-web-utils';

import {
    AccountInit,
    AccountUnset,
    ConfirmEmail,
    DeactivateAccount,
    EditingPersonalInformation,
    RegisterAccount,
    ResendConfirmation,
    ResetPassword,
    SendDeactivateAccountLink,
    SendResetPasswordLink,
    UpdateAccount,
    UpdatePassword,
} from './actions';
import {
    DEACTIVATE_ACCOUNT_FAIL,
    DEACTIVATE_ACCOUNT_SUCCESS,
    RESEND_CONFIRMATION_SUCCESS,
    SEND_DEACTIVATION_SUCCESS,
    UPDATE_ACCOUNT_SUCCESS,
    UPDATE_PASSWORD_SUCCESS,
} from './messages';
import { Account } from '../../models';

export interface StateModel {
    account: Account;
    isEditingPersonalInformation: boolean;
}

@State<StateModel>({
    name: 'account',
    defaults: {
        account: null,
        isEditingPersonalInformation: false,
    },
})
@Injectable()
export class AccountState {
    @Selector()
    static account(state: StateModel) {
        return state.account;
    }

    @Selector()
    static isEditingPersonalInformation(state: StateModel) {
        return state.isEditingPersonalInformation;
    }

    constructor(private dataAccess: BazaAccountDataAccess, private effectsUtil: EffectsUtil) {}

    @Action(EditingPersonalInformation)
    isEditingPersonalInformation(ctx: StateContext<StateModel>) {
        return ctx.patchState({ isEditingPersonalInformation: !ctx.getState().isEditingPersonalInformation });
    }

    @Action(AccountInit)
    accountInit(ctx: StateContext<StateModel>) {
        return this.dataAccess.currentAccount().pipe(tap((account) => ctx.patchState({ account })));
    }

    @Action(AccountUnset)
    accountUnset(ctx: StateContext<StateModel>) {
        ctx.patchState({ account: undefined });
    }

    @Action(ConfirmEmail)
    confirmEmail(ctx: StateContext<StateModel>, action: ConfirmEmail) {
        return this.dataAccess.confirmEmail(action.data).pipe(this.effectsUtil.tryCatchNone$());
    }

    @Action(DeactivateAccount, { cancelUncompleted: true })
    deactivateAccount(ctx: StateContext<void>, action: DeactivateAccount) {
        return this.dataAccess
            .deactivateAccount(action.data)
            .pipe(this.effectsUtil.tryCatch$(DEACTIVATE_ACCOUNT_SUCCESS, DEACTIVATE_ACCOUNT_FAIL));
    }

    @Action(RegisterAccount)
    registerAccount(_ctx: StateContext<StateModel>, action: RegisterAccount) {
        return this.dataAccess.registerAccount(action.data).pipe(this.effectsUtil.tryCatchNone$());
    }

    @Action(ResendConfirmation)
    resendConfirmation(ctx: StateContext<StateModel>, action: ResendConfirmation) {
        return this.dataAccess.sendConfirmEmailLink(action.data).pipe(this.effectsUtil.tryCatchSuccess$(RESEND_CONFIRMATION_SUCCESS));
    }

    @Action(ResetPassword)
    resetPassword(_ctx: StateContext<StateModel>, action: ResetPassword) {
        return this.dataAccess.resetPassword(action.data).pipe(this.effectsUtil.tryCatchNone$());
    }

    @Action(SendDeactivateAccountLink)
    sendDeactivateAccountLink(ctx: StateContext<StateModel>, action: SendDeactivateAccountLink) {
        return this.dataAccess.sendDeactivateAccountLink(action.data).pipe(this.effectsUtil.tryCatchSuccess$(SEND_DEACTIVATION_SUCCESS));
    }

    @Action(SendResetPasswordLink)
    sendResetPasswordLink(_ctx: StateContext<StateModel>, action: SendResetPasswordLink) {
        return this.dataAccess.sendResetPasswordLink(action.data).pipe(this.effectsUtil.tryCatchNone$());
    }

    @Action(UpdateAccount)
    updateAccount(ctx: StateContext<StateModel>, action: UpdateAccount) {
        return this.dataAccess.updateProfile(action.data).pipe(
            tap((account) => ctx.patchState({ account: account })),
            this.effectsUtil.tryCatchSuccess$(UPDATE_ACCOUNT_SUCCESS),
        );
    }

    @Action(UpdatePassword)
    updatePassword(_ctx: StateContext<StateModel>, action: UpdatePassword) {
        return this.dataAccess.changePassword(action.data).pipe(this.effectsUtil.tryCatchSuccess$(UPDATE_PASSWORD_SUCCESS));
    }
}
