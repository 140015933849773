import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
    name: 'priceCustom',
})
// show price with custom cents
export class PriceCustomPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) {}

    transform(value: number | string): string {
        const price = this.currencyPipe.transform(Number(value), 'USD', '$', '1.2-2');

        return price.slice(0, price.lastIndexOf('.')) + `<span>${price.slice(price.lastIndexOf('.'))}</span>`;
    }
}
