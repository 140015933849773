import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsArray, IsNotEmpty, IsObject, IsOptional, IsString, Length, ValidateNested } from 'class-validator';
import { CrudExportToCsvField } from './crud-export-to-csv-field.dto';
import { CrudListRequestDto } from './crud-list-request.dto';
import { BAZA_CRUD_CONSTANTS } from '../constants/baza-crud.constants';
import { Type } from 'class-transformer';

type ListRequest<T> = Omit<CrudListRequestDto<T>, 'sort' | 'includeFields' | 'excludeFields'>;

export class CrudExportToCsvRequest<T, LIST_REQUEST extends ListRequest<T>> {
    @ApiModelProperty({
        description: 'List Request',
        type: () => CrudListRequestDto,
    })
    @IsObject()
    @IsNotEmpty()
    @Type(() => CrudListRequestDto)
    listRequest: LIST_REQUEST;

    @ApiModelProperty({
        type: CrudExportToCsvField,
        isArray: true,
    })
    @ValidateNested({ each: true })
    @Type(() => CrudExportToCsvField)
    @IsArray()
    @IsNotEmpty()
    @Type(() => CrudExportToCsvField)
    fields: Array<CrudExportToCsvField<T>>;

    @ApiModelProperty({
        description: 'Delimiter (separator) for output CSV',
        default: BAZA_CRUD_CONSTANTS.csvDefaultDelimiter,
        required: false,
    })
    @Length(1)
    @IsString()
    @IsOptional()
    delimiter?: string;
}
