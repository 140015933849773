import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BazaRegistryNgService } from '@scaliolabs/baza-core-ng';
import { LinkConfig, LinkTargetEnum } from '../models';
import { BazaWebUtilSharedService } from './baza-web-util-shared.service';

@Injectable()
export class BazaLinkUtilSharedService {
    constructor(
        private readonly registry: BazaRegistryNgService,
        private readonly router: Router,
        private readonly wts: BazaWebUtilSharedService,
    ) {}

    public getI18nLinkConfigs(linkConfigBasePath: string): Array<LinkConfig> {
        const configuration = this.wts.getI18nLabel(linkConfigBasePath);
        return Array.isArray(configuration) ? configuration : [configuration];
    }

    public getUrlText(linkToNavigate: LinkConfig): string {
        if (linkToNavigate?.isCMSLink && !linkToNavigate?.text) {
            return this.getCMSLinkFromReg(linkToNavigate?.link);
        }

        return linkToNavigate?.text;
    }

    public navigate(linkToNavigate: LinkConfig): void {
        this.isAppLink(linkToNavigate) ? this.openAppLink(linkToNavigate) : this.openExtLink(linkToNavigate);
    }

    // #region Private Functions
    openAppLink(linkToNavigate: LinkConfig): void {
        const linkTarget = this.getLinkTarget(linkToNavigate);

        if (linkTarget === LinkTargetEnum.blank) {
            window.open(this.getAppLinkSerializedUrl(linkToNavigate));
        } else {
            this.router.navigate(linkToNavigate?.commands, linkToNavigate?.navExtras);
        }
    }

    getAppLinkSerializedUrl(linkToNavigate: LinkConfig) {
        const urlTree = this.router.createUrlTree(linkToNavigate?.commands, linkToNavigate?.navExtras);
        const serializedUrl = this.router.serializeUrl(urlTree) ?? '';

        return serializedUrl;
    }

    openExtLink(linkToNavigate: LinkConfig): void {
        let url = linkToNavigate?.link?.trim();
        const linkTarget = this.getLinkTarget(linkToNavigate);

        if (linkToNavigate?.isCMSLink) {
            url = this.getCMSLinkFromReg(linkToNavigate?.link);
        }

        if (linkToNavigate['isMailLink']) {
            url = `mailto:${url}`;
        }

        if (!this.isExternalLinkRegexMatch(url)) {
            url = this.router.serializeUrl(this.router.createUrlTree([url]));
            this.router.navigateByUrl(url);
        } else {
            window.open(url, linkTarget);
        }
    }

    getCMSLinkFromReg(linkId: string): string {
        return this.registry.value(linkId);
    }

    getLinkTarget(linkToNavigate: LinkConfig): LinkTargetEnum {
        const defaultTarget = this.isAppLink(linkToNavigate) ? LinkTargetEnum.self : LinkTargetEnum.blank;
        return linkToNavigate?.target ?? defaultTarget;
    }

    // #endregion

    // #region Utility Functions
    isAppLink(linkToNavigate: LinkConfig): boolean {
        return linkToNavigate?.commands ? true : false;
    }

    isExternalLinkRegexMatch(url: string) {
        const externalUrlPattern =
            /^(https?:\/\/|www\.|mailto:|ftp:\/\/|ftps:\/\/|tel:|sms:|file:\/\/|data:|ssh:\/\/|irc:\/\/|geo:|sip:)[^\s/$.?#].[^\s]*$/;

        return externalUrlPattern.test(url);
    }
    // #endregion
}
