export const WebUiWithdrawFundsFormEnI18n = {
    fields: {
        amount: {
            label: `Withdrawal Amount`,
            validators: {
                required: 'Please enter the amount to be withdrawn',
            },
        },
    },
    alerts: {
        withdrawalError: {
            label: `The funds were not withdrawn from the account. <br/> Please try again later or reach out to <a data-link="contactLink"></a>.`,
        },
    },
    linkConfigs: [
        {
            key: 'contactLink',
            link: 'bazaContentTypes.contacts.email',
            isCMSLink: true,
            isMailLink: true,
            text: 'technical support',
        },
    ],
};
