import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { BazaContentTypesDataAccessModule } from '@scaliolabs/baza-content-types-data-access';

import { MarketingState } from './state';

@NgModule({
    imports: [BazaContentTypesDataAccessModule, NgxsModule.forFeature([MarketingState])],
})
export class MarketingStateModule {}
