<nz-modal
    [nzTitle]="wts.getI18nLabel(i18nBasePath, 'title')"
    [nzAutofocus]="null"
    [nzClosable]="true"
    [nzFooter]="null"
    [nzVisible]="true"
    nzWrapClassName="regcf-consent"
    (nzOnCancel)="consentCancelled.emit()"
    #regCfConsentModalEl
    appLinkify
    [linkConfigPath]="i18nBasePath + '.linkConfigs'">
    <ng-container *nzModalContent>
        <form
            class="ant-form-inverse"
            nz-form
            nzLayout="vertical"
            [formGroup]="consentForm">
            <div class="regcf-consent__checkbox">
                <nz-form-item>
                    <nz-form-control>
                        <nz-checkbox-wrapper>
                            <label
                                nz-checkbox
                                formControlName="accepted">
                                <span [innerHtml]="wts.getI18nLabel(i18nBasePath, 'agreement') | sanitizeHtml"></span>
                            </label>
                        </nz-checkbox-wrapper>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="regcf-agreement">
                <div class="regcf-agreement__scroll">
                    <ul class="regcf-agreement__list">
                        <ng-container *ngFor="let clause of clauses; let i = index">
                            <li
                                class="regcf-agreement__item"
                                [innerHTML]="getClauseLabel(i) | sanitizeHtml"></li>
                        </ng-container>
                    </ul>
                </div>
            </div>

            <div class="regcf-consent__cta">
                <nz-form-item>
                    <button
                        nz-button
                        [class]="wts.getI18nLabel(i18nBasePath, 'cta.btnClass')"
                        [disabled]="isSubmitDisabled"
                        (click)="consentAccepted.emit()">
                        {{ wts.getI18nLabel(i18nBasePath, 'cta.text') }}
                    </button>
                </nz-form-item>
            </div>
        </form>
    </ng-container>
</nz-modal>
