export const WebUiInvestorProfileEnI18n = {
    title: `Investor Account`,
    editPopoverLabel: `This is your Investor Account information. You can review it and make changes if needed. <br/> <br/> <a class="edit-link">Edit</a>`,
    sections: [
        {
            title: `Personal Information`,
            key: `personal`,
            blocks: [
                {
                    key: `name`,
                    label: `Legal Name`,
                },
                {
                    key: `dob`,
                    label: `Date of Birth`,
                },
                {
                    key: `ssn`,
                    label: `Social Security Number`,
                },
                {
                    key: `uploaded-doc`,
                    label: `Uploaded Document`,
                },
                {
                    key: `phone`,
                    label: `Phone Number`,
                },
                {
                    key: `citizenship`,
                    label: `Citizenship`,
                },
                {
                    key: `address`,
                    label: `Residential Address`,
                },
            ],
        },
        {
            title: `Investor Profile`,
            key: `investor`,
            blocks: [
                {
                    key: `accredited-status`,
                    label: `Are you an accredited investor?`,
                },
                {
                    key: `finra-association`,
                    label: `Are you or anyone in your household associated with a FINRA member, organization or the SEC?`,
                },
                {
                    key: `finra-relation`,
                    label: `Please describe your relationship with such member(s) of your household?`,
                },
            ],
        },
    ],
    emptyStates: {
        new: {
            title: `Investor Account`,
            descr: `You will see your investor account details here once you have made your first purchase`,
            btnCTA: {
                label: `Verify Your Profile`,
            },
        },
        inProgress: {
            title: `Investor Account`,
            descr: `Please complete your profile verification to access the Investor Account`,
            btnCTA: {
                label: `Update Your Profile`,
            },
        },
    },
};
