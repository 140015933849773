import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { UtilModule } from '@scaliolabs/baza-web-utils';
import { DividendAlertModule, FooterMiniModule, FooterModule, HeaderModule } from '@scaliolabs/web/ui-components';

import { LayoutComponent } from './layout.component';

@NgModule({
    declarations: [LayoutComponent],
    imports: [
        CommonModule,
        DividendAlertModule,
        FooterMiniModule,
        FooterModule,
        HeaderModule,
        NzLayoutModule,
        ReactiveFormsModule,
        RouterModule,
        UtilModule,
    ],
})
export class LayoutModule {}
