import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { UtilModule } from '@scaliolabs/baza-web-utils';

import { FooterSocialComponent } from './footer-social.component';

@NgModule({
    declarations: [FooterSocialComponent],
    exports: [FooterSocialComponent],
    imports: [CommonModule, NzMenuModule, RouterModule, UtilModule],
})
export class FooterSocialModule {}
