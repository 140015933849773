import { Component } from '@angular/core';
import { BazaRegistryNgService } from '@scaliolabs/baza-core-ng';

@Component({
    selector: 'app-footer-links',
    templateUrl: './footer-links.component.html',
    styleUrls: ['./footer-links.component.less'],
})
export class FooterLinksComponent {
    constructor(private readonly registry: BazaRegistryNgService) {}

    public getServiceLink(link): string {
        return this.registry.value(link);
    }
}
