<nz-modal
    [nzTitle]="wts.getI18nLabel(i18nBasePath, 'title')"
    [nzAutofocus]="null"
    [nzClosable]="isModalClosable"
    [nzFooter]="null"
    [nzVisible]="true"
    nzWrapClassName="regcf-limit"
    (nzOnCancel)="limitCancelled.emit()"
    #regCfLimitModalEl
    appLinkify
    [linkConfigPath]="i18nBasePath + '.linkConfigs'">
    <ng-container *nzModalContent>
        <div
            class="regcf-limit__descr"
            [innerHtml]="wts.getI18nLabel(i18nBasePath, 'descr.label') | sanitizeHtml"></div>

        <!-- Warning / Error Alert -->
        <div class="regcf-limit__alert">
            <nz-alert
                *ngIf="showLowRegCfLimitWarning"
                [nzMessage]="lowRegCfLimitMessage"
                nzType="warning"></nz-alert>
        </div>

        <ng-template #lowRegCfLimitMessage>
            <div
                class="regcf-limit__message"
                [innerHtml]="wts.getI18nLabel(i18nBasePath, 'alerts.lowLimit.text') | sanitizeHtml"></div>
        </ng-template>

        <!-- Form -->
        <div class="regcf-form">
            <form
                class="ant-form-inverse"
                nz-form
                nzLayout="vertical"
                [formGroup]="limitForm">
                <div class="regcf-form__row">
                    <div class="regcf-form__investment">
                        <nz-form-item>
                            <nz-form-label
                                nzFor="externalInvestment"
                                [innerHTML]="
                                    wts.getI18nLabel(i18nBasePath, 'form.fields.externalInvestment.label') | sanitizeHtml
                                "></nz-form-label>

                            <nz-form-control>
                                <nz-input-number
                                    formControlName="externalRegCfInvestments"
                                    id="externalInvestment"
                                    name="externalInvestment"
                                    type="number"
                                    nzPlaceHolder="$"
                                    [nzPrecisionMode]="amountConfig.precisionMode"
                                    [nzMin]="0"
                                    [nzMax]="amountConfig.maxValue"
                                    [nzStep]="amountConfig.step"
                                    [nzPrecision]="amountConfig.precision"
                                    [nzFormatter]="wts.dollarFormat"
                                    [nzParser]="wts.dollarParse"
                                    appAmountMask
                                    (nzBlur)="onExternalRegCfInvestmentChange()"></nz-input-number>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <!-- Hide this row if checkbox is unchecked -->
                <div
                    *ngIf="showCheckSpendMoreSection"
                    class="regcf-form__row regcf-form__row-additional">
                    <nz-form-item>
                        <nz-form-label
                            nzFor="yearlyIncome"
                            [innerHTML]="wts.getI18nLabel(i18nBasePath, 'form.fields.yearlyIncome.label') | sanitizeHtml"></nz-form-label>

                        <nz-form-control [nzErrorTip]="yearlyIncomeErrorTemplate">
                            <nz-input-number
                                formControlName="yearlyIncome"
                                id="yearlyIncome"
                                name="yearlyIncome"
                                type="number"
                                nzPlaceHolder="$"
                                [nzPrecisionMode]="amountConfig.precisionMode"
                                [nzMin]="0"
                                [nzStep]="0.1"
                                [nzPrecision]="amountConfig.precision"
                                [nzFormatter]="wts.dollarFormat"
                                [nzParser]="wts.dollarParse"
                                appAmountMask></nz-input-number>
                        </nz-form-control>

                        <ng-template
                            #yearlyIncomeErrorTemplate
                            let-control>
                            <span>
                                {{ getErrorMessage(control, 'yearlyIncome') }}
                            </span>
                        </ng-template>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label
                            nzFor="netWorth"
                            [innerHTML]="wts.getI18nLabel(i18nBasePath, 'form.fields.netWorth.label') | sanitizeHtml"></nz-form-label>

                        <nz-form-control [nzErrorTip]="netWorthTemplate">
                            <nz-input-number
                                formControlName="netWorth"
                                id="netWorth"
                                name="netWorth"
                                type="number"
                                nzPlaceHolder="$"
                                [nzPrecisionMode]="amountConfig.precisionMode"
                                [nzMin]="0"
                                [nzStep]="0.1"
                                [nzPrecision]="amountConfig.precision"
                                [nzFormatter]="wts.dollarFormat"
                                [nzParser]="wts.dollarParse"
                                appAmountMask></nz-input-number>
                        </nz-form-control>

                        <ng-template
                            #netWorthTemplate
                            let-control>
                            <span>
                                {{ getErrorMessage(control, 'netWorth') }}
                            </span>
                        </ng-template>
                    </nz-form-item>
                </div>

                <div class="regcf-form__row regcf-form__row-recalculate">
                    <div class="regcf-form__checkSpendMore">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-checkbox-wrapper (nzOnChange)="onCheckSpendMoreChange()">
                                    <label
                                        nz-checkbox
                                        formControlName="checkSpendMore">
                                        <span
                                            [innerHTML]="
                                                wts.getI18nLabel(i18nBasePath, 'form.fields.limitCheckbox.label') | sanitizeHtml
                                            "></span>
                                    </label>
                                </nz-checkbox-wrapper>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div class="regcf-form__recalculate">
                        <nz-form-item>
                            <button
                                nz-button
                                [class]="wts.getI18nLabel(i18nBasePath, 'form.fields.recalculateCta.btnClass')"
                                (click)="calculateLimit()"
                                [disabled]="loader.isLoading$ | async"
                                [nzLoading]="!triggerSubmit && loader.isLoading$ | async">
                                {{ wts.getI18nLabel(i18nBasePath, 'form.fields.recalculateCta.label') }}
                            </button>
                        </nz-form-item>
                    </div>
                </div>
            </form>
        </div>

        <!-- Limit Preview -->
        <div class="regcf-preview">
            <div class="regcf-preview__row">
                <div
                    class="regcf-preview__label"
                    [innerHtml]="wts.getI18nLabel(i18nBasePath, 'preview.descr') | sanitizeHtml"></div>

                <div class="regcf-preview__icon">
                    <a
                        nz-popover
                        nzPopoverPlacement="right"
                        [nzPopoverContent]="wts.getI18nLabel(i18nBasePath, 'preview.popover')">
                        <i class="icon icon-info-circle icon-wt"></i>
                    </a>
                </div>
            </div>

            <div
                class="regcf-preview__limit"
                *ngIf="regCfLimits$ | async as recalculatedLimit">
                {{ (recalculatedLimit?.limitCents ?? 0) / 100 | priceCents }}
            </div>
        </div>

        <!-- Footer -->
        <div class="regcf-footer">
            <div class="regcf-footer__cta">
                <button
                    nz-button
                    [class]="wts.getI18nLabel(i18nBasePath, 'footer.cta.btnClass')"
                    (click)="calculateLimit(true)"
                    [disabled]="loader.isLoading$ | async"
                    [nzLoading]="triggerSubmit && loader.isLoading$ | async">
                    {{ wts.getI18nLabel(i18nBasePath, 'footer.cta.text') }}
                </button>
            </div>

            <div
                *ngIf="!isModalClosable"
                class="regcf-footer__backlink">
                <a (click)="goBack()">
                    {{ wts.getI18nLabel(i18nBasePath, 'footer.backLinkText') }}
                </a>
            </div>
        </div>
    </ng-container>
</nz-modal>
