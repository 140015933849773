import { TransactionFilter, TransactionFilterBindings } from '../../models/portfolio/transaction-filter.interface';

export class ChangeTransactionFilter {
    static readonly type = '[Portfolio] Change Transaction Filter';

    constructor(public transactionFilter: TransactionFilter) {}
}

export class ChangeTransactionFilterBindings {
    static readonly type = '[Portfolio] Change Transaction Filter Bindings';

    constructor(public transactionFilterBindings: TransactionFilterBindings) {}
}

export class ChangeTransactionsPage {
    static readonly type = '[Portfolio] Change Transactions Page';

    constructor(public index: number) {}
}

export class GetAssets {
    static readonly type = '[Portfolio] GetAssets';
}

export class GetTransactions {
    static readonly type = '[Portfolio] GetTransactions';
}

export class GetStatements {
    static readonly type = '[Portfolio] GetStatements';
}

export class GetTotals {
    static readonly type = '[Portfolio] GetTotals';
}
