import { Component, ChangeDetectionStrategy, Renderer2 } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DividendAlertState, LayoutParams } from '@scaliolabs/web/data-access';

@UntilDestroy()
@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
    @Select(DividendAlertState.show)
    showAlert$: Observable<boolean>;

    public layout;
    public layoutParams: LayoutParams;
    public openedMenu: boolean;

    constructor(private readonly route: ActivatedRoute, private renderer: Renderer2, private readonly router: Router) {
        this.router.events
            .pipe(
                untilDestroyed(this),
                filter((event: Event) => event instanceof NavigationEnd),
                tap((event: Event) => {
                    // open login modal
                    if (event instanceof NavigationEnd && event.url.includes('login=true')) {
                        this.router.navigate(['.', { outlets: { modal: ['auth', 'login'] } }]);
                    }

                    // open signup modal
                    if (event instanceof NavigationEnd && event.url.includes('signup=true') && event.url.includes('referral')) {
                        this.router.navigate(['.', { outlets: { modal: ['auth', 'signup'] } }], {
                            queryParams: { referralCode: this.route.snapshot?.queryParams?.referral },
                        });
                    }

                    // close menu on route change
                    this.openedMenu = false;

                    // remove class on route change
                    this.renderer.removeClass(document.body, 'overflow');
                }),
                map(() => {
                    return this.route;
                }),
                map((response) => {
                    while (response.firstChild) {
                        response = response.firstChild;
                    }
                    return response;
                }),
                filter((response) => response.outlet === 'primary'),
                mergeMap((response) => response.data),
            )
            .subscribe((response) => {
                this.layout = response.layout || 'full';

                this.layoutParams = response.layoutParams || {
                    bg: false,
                    center: false,
                    wrapper: false,
                };
            });
    }
}
