import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AccountVerificationDto, BazaNcBootstrapDto, KYCStatus } from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { KycDisapprovedAlertComponent } from '../kyc-disapproved-alert';
import { IMaskModule } from 'angular-imask';

@Component({
    selector: 'app-investor-profile',
    templateUrl: './investor-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        UtilModule,
        KycDisapprovedAlertComponent,
        NzGridModule,
        NzSkeletonModule,
        NzEmptyModule,
        NzDividerModule,
        NzButtonModule,
        NzPopoverModule,
        IMaskModule,
    ],
})
export class InvestorProfileComponent implements OnInit {
    @Input()
    initData: BazaNcBootstrapDto;

    @Input()
    verification: AccountVerificationDto;

    @Output()
    redirectCTAClicked = new EventEmitter<void>();

    fullName: string;
    addressLineOne: string;
    addressLineTwo: string;
    i18nBasePath = 'uic.investorProfile';
    i18nSections: Record<string, unknown>[] = [];
    KycStatus = KYCStatus;

    @HostListener('window:click', ['$event.target'])
    handleClick(targetEl: HTMLElement) {
        if (targetEl && targetEl?.classList?.contains('edit-link')) {
            this.redirectCTAClicked.emit();
        }
    }

    constructor(public readonly wts: BazaWebUtilSharedService) {
        this.i18nSections = this.wts.getI18nLabel(this.i18nBasePath, 'sections');
    }

    ngOnInit(): void {
        this.addressLineOne = `${this.verification?.personalInformation?.residentialStreetAddress1}, `;
        if (this.verification?.personalInformation?.residentialStreetAddress2) {
            this.addressLineOne += `${this.verification?.personalInformation?.residentialStreetAddress2}, `;
        }

        this.addressLineTwo = '';
        this.addressLineTwo += `${this.verification?.personalInformation?.residentialCity}, ${
            this.verification?.personalInformation?.residentialState ? this.verification?.personalInformation?.residentialState : ''
        } ${this.verification?.personalInformation?.residentialZipCode}`;

        this.fullName =
            this.verification?.personalInformation?.firstName +
            (this.verification?.personalInformation?.lastName ? ' ' + this.verification?.personalInformation?.lastName : '');
    }

    geti18nSection(sectionKey: string) {
        return this.i18nSections?.find((x) => x.key === sectionKey);
    }
    public geti18nSectionTitle(sectionKey: string): string {
        return (this.geti18nSection(sectionKey)?.title as string) ?? ``;
    }

    public geti18nBlockLabel(sectionKey: string, blockKey: string): string {
        return (
            (((this.geti18nSection(sectionKey)?.blocks as Record<string, unknown>[]) ?? [])?.find((y) => y.key === blockKey)
                ?.label as string) ?? ``
        );
    }
}
