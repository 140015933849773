<nz-alert
    class="kyc-alert"
    [nzType]="wts.getI18nLabel(i18nBasePath, 'type') ?? 'warning'"
    [nzMessage]="alertMsg"></nz-alert>
<ng-template #alertMsg>
    <div
        class="kyc-alert__message"
        appLinkify
        [linkConfigPath]="i18nBasePath + '.linkConfigs'"
        [innerHTML]="wts.getI18nLabel(i18nBasePath, 'message') | sanitizeHtml"></div>
</ng-template>
