<section class="payment-block">
    <ng-container *ngTemplateOutlet="header"></ng-container>
    <ng-container
        *ngIf="{
            cashOutAccount: dwollaDefaultCashOutAccount$ | async
        } as data">
        <div
            nz-row
            class="payment-block__row payment-block__row-bank">
            <div
                nz-col
                nzXs="12">
                <ng-container *ngIf="data.cashOutAccount; else skeleton">
                    <app-bank-account-payout
                        [initData]="initData"
                        [isAnyAssetPurchased]="isAnyAssetPurchased"
                        [dwollaDefaultCashOutAccount]="data.cashOutAccount"
                        (refreshCashOutData)="onRefreshCashOutData()"></app-bank-account-payout>
                </ng-container>
            </div>
        </div>
    </ng-container>
</section>

<ng-template #skeleton>
    <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>
