import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { UtilModule } from '@scaliolabs/baza-web-utils';

import { FooterComponent } from './footer.component';
import { FooterLinksModule } from '../footer-links';
import { FooterMenuModule } from '../footer-menu';
import { FooterSocialModule } from '../footer-social';

@NgModule({
    declarations: [FooterComponent],
    exports: [FooterComponent],
    imports: [
        CommonModule,
        FooterLinksModule,
        FooterMenuModule,
        FooterSocialModule,
        NzDividerModule,
        NzGridModule,
        NzLayoutModule,
        RouterModule,
        UtilModule,
    ],
})
export class FooterModule {}
