import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BazaFormValidatorService, BazaLoadingService } from '@scaliolabs/baza-core-ng';
import { Domicile, ListStatesResponseDto } from '@scaliolabs/baza-nc-shared';
import { PhoneCountryCode } from '@scaliolabs/baza-web-ui-components';
import {
    BazaWebUtilSharedService,
    LoaderService,
    ValidationsPreset,
    i18nValidationTypesEnum,
    isAdultValidator,
    onlyNumbersValidator,
    restrictedCharsValidator,
    whitespaceValidator,
    zipcodeWhitespaceValidator,
} from '@scaliolabs/baza-web-utils';
import IMask from 'imask';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { filter, shareReplay, take, tap } from 'rxjs/operators';
import {
    ApplyPersonalInfo,
    GetListStates,
    Verification,
    VerificationState,
    PersonalInformation,
    UploadedSSN,
    VerificationInfoForm,
} from '../data-access';
import { subYears } from 'date-fns';

@Component({
    selector: 'app-verification-stepper-info',
    templateUrl: './stepper-info.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerificationInfoComponent implements OnInit, OnChanges {
    @Input()
    proceedToNextStep?: boolean;

    @Output()
    moveToNextStep: EventEmitter<void> = new EventEmitter();

    listStates$: Observable<ListStatesResponseDto>;
    verification$: Observable<Verification>;
    formResources$ = this.store.select(VerificationState.formResources).pipe(shareReplay());

    public maskSSN = {
        mask: 'nnn-vv-cccc',
        lazy: true,
        overwrite: true,
        autofix: true,
        blocks: {
            nnn: { mask: IMask.MaskedRange, from: 0, to: 999, maxLength: 3 },
            vv: { mask: IMask.MaskedRange, from: 0, to: 99, maxLength: 2 },
            cccc: { mask: IMask.MaskedRange, from: 0, to: 9999, maxLength: 4 },
        },
    };

    public fileList: NzUploadFile[];
    public numericCode: string;
    public phoneCountryCode: string;
    public residentialState: string;
    public selectedResidentialCountry: string;
    public uploadedSSN: UploadedSSN;
    public verificationInfoForm: VerificationInfoForm;
    isFirstTimeLoad = true;

    ssnValue: string;
    maskElRef: IMask.InputMask<IMask.AnyMaskedOptions>;

    @ViewChild('ssnEl') ssnElRef: ElementRef;

    get ssnControl(): AbstractControl {
        return this.verificationInfoForm.controls.ssn;
    }

    get stateControl(): AbstractControl {
        return this.verificationInfoForm.controls.residentialState;
    }

    get dobControl(): AbstractControl {
        return this.verificationInfoForm.controls.dateOfBirth;
    }

    genericValidationErrors: Record<string, string>;

    i18nBasePath = 'dwvf.info';
    i18nFormPath = `${this.i18nBasePath}.form`;
    i18nFormFieldsPath = `${this.i18nBasePath}.form.fields`;
    vfConfig = this.store.selectSnapshot(VerificationState.vfConfig);

    dateOfBirth = '';
    minus18Years = subYears(new Date(), 18);
    isDisplayed = true;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly cdr: ChangeDetectorRef,
        private readonly notification: NzNotificationService,
        private readonly router: Router,
        private readonly store: Store,
        public readonly bazaFormValidatorService: BazaFormValidatorService,
        public readonly wts: BazaWebUtilSharedService,
        public readonly loader: BazaLoadingService,
    ) {
        this.onFormReset();
    }

    public disabledDate(current: Date) {
        return current > subYears(new Date(), 18);
    }

    public ngOnInit(): void {
        this.listStates$ = this.store.select(VerificationState.listStates).pipe(
            filter((response) => !!response),
            tap((response) => {
                this.toggleResidentialStateField(response?.states?.length > 0);
                if (response?.states?.length === 0) {
                    this.residentialState = 'N/A';
                }
            }),
        );

        this.verification$ = this.store.select(VerificationState.verification).pipe(
            filter((response) => !!response),
            take(1),
            tap((response) => {
                if (response.personalInformation) {
                    this.residentialState = response.personalInformation.residentialState;
                    this.phoneCountryCode = response.personalInformation.phoneCountryCode;

                    // set default country code & country
                    this.numericCode = response.personalInformation.phoneCountryNumericCode || '840';
                    this.selectedResidentialCountry = response.personalInformation.residentialCountry || 'United States';

                    // set uploaded SSN file
                    this.uploadedSSN = {
                        ssnDocumentFileName: response.personalInformation.ssnDocumentFileName,
                        ssnDocumentId: response.personalInformation.ssnDocumentId,
                        ssnDocumentUrl: response.personalInformation.ssnDocumentUrl,
                    };

                    const citizenship =
                        response.personalInformation.citizenship?.length > 0
                            ? response.personalInformation.citizenship
                            : Domicile.USCitizen;

                    this.dateOfBirth = response?.personalInformation?.dateOfBirth ?? '';

                    this.verificationInfoForm.patchValue({
                        ...response.personalInformation,
                        citizenship: citizenship,
                        hasntSsn: response.personalInformation.hasSsn !== null ? !response.personalInformation.hasSsn : null,
                    } as PersonalInformation);

                    this.cdr.detectChanges();

                    this.ssnValue = response.personalInformation.ssn;
                    if (!this.ssnValue && this.ssnElRef) {
                        this.maskElRef = IMask(this.ssnElRef.nativeElement, this.maskSSN);
                    }
                }
            }),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!(changes?.proceedToNextStep?.firstChange ?? true) && (changes?.proceedToNextStep?.currentValue ?? true)) {
            this.redirectToNextStep();
        }
    }

    onOpenChange(isOpened: boolean) {
        if (this.dateOfBirth === '') {
            if (isOpened) {
                this.verificationInfoForm.controls.dateOfBirth.patchValue(this.minus18Years);
            } else {
                this.verificationInfoForm.controls.dateOfBirth.patchValue(this.dateOfBirth);
                this.verificationInfoForm.controls.dateOfBirth.setErrors(null);
                this.verificationInfoForm.controls.dateOfBirth.markAsPristine();
                this.verificationInfoForm.controls.dateOfBirth.markAsUntouched();
            }

            this.verificationInfoForm.controls.dateOfBirth.updateValueAndValidity();
        }
    }

    ssnEdit(event) {
        if (event.target.value.length === 11) {
            this.verificationInfoForm.controls.ssn.reset(event.target.value);
        }
    }

    // change state required/not required
    private toggleResidentialStateField(isEnable: boolean) {
        if (isEnable) {
            this.verificationInfoForm.controls.residentialState.enable();
        } else {
            this.verificationInfoForm.controls.residentialState.reset({ value: '', disabled: true });
        }

        this.verificationInfoForm.updateValueAndValidity();
    }

    // onCodeChange
    public onCodeChange(code: PhoneCountryCode) {
        this.phoneCountryCode = code.phoneCode;
        this.numericCode = code.numericCode;
    }

    // onCountryChange
    public onCountryChange(country) {
        if (country) {
            this.store.dispatch(new GetListStates(country));
            if (!this.isFirstTimeLoad) {
                this.residentialState = null;
                this.stateControl.reset();
            }
            this.isFirstTimeLoad = false;
        }
    }

    // onFileUpload
    public onFileUpload = (file: NzUploadFile): boolean => {
        this.fileList = [file];
        return false;
    };

    public onSSNFocus() {
        if (this.ssnControl.value && this.ssnValue?.includes('*')) {
            this.resetSSNControl();
            this.maskElRef = IMask(this.ssnElRef.nativeElement, this.maskSSN);
        }
    }

    public onSSNBlur() {
        if (!this.ssnControl.value && this.ssnValue?.includes('*')) {
            this.resetSSNControl();
            this.ssnControl.patchValue(this.ssnValue);
        } else {
            this.ssnValue = this.ssnControl.value;
        }
    }

    resetSSNControl() {
        this.ssnControl.reset();
        this.maskElRef?.destroy();
    }

    // onFormReset
    public onFormReset(): void {
        this.verificationInfoForm = this.fb.group({
            firstName: [
                '',
                Validators.compose([Validators.required, whitespaceValidator(), restrictedCharsValidator({ bypassChars: ['-'] })]),
            ],
            lastName: [
                '',
                Validators.compose([Validators.required, whitespaceValidator(), restrictedCharsValidator({ bypassChars: ['-'] })]),
            ],
            dateOfBirth: ['', Validators.compose([Validators.required, isAdultValidator()])],
            ssn: ['', Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(11)])],
            hasntSsn: false,
            phone: ['', Validators.compose([Validators.required])],
            citizenship: ['', Validators.required],
            residentialStreetAddress1: ['', Validators.compose([Validators.required, whitespaceValidator()])],
            residentialStreetAddress2: [''],
            residentialCity: ['', Validators.compose([Validators.required, whitespaceValidator()])],
            residentialState: ['', Validators.required],
            residentialZipCode: ['', Validators.compose([Validators.required, whitespaceValidator()])],
            residentialCountry: ['', Validators.required],
        }) as VerificationInfoForm;

        this.onFormChanges();
    }

    // form value changes subscription
    private onFormChanges(): void {
        this.verificationInfoForm.valueChanges.pipe(take(1)).subscribe((changes: PersonalInformation) => {
            if (changes.hasntSsn) {
                this.resetSSNControl();
                this.ssnControl.disable();
                this.ssnValue = null;
            } else if (this.verificationInfoForm.controls.ssn.disabled) {
                this.verificationInfoForm.controls.ssn.setValidators(
                    Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(11)]),
                );
                this.verificationInfoForm.controls.ssn.enable();
                this.maskElRef = IMask(this.ssnElRef.nativeElement, this.maskSSN);
            }

            if (changes.residentialCountry === 'United States') {
                this.verificationInfoForm.controls.residentialZipCode.setValidators(
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(5),
                        Validators.maxLength(5),
                        zipcodeWhitespaceValidator(),
                        onlyNumbersValidator(),
                    ]),
                );
            } else {
                this.verificationInfoForm.controls.residentialZipCode.setValidators(
                    Validators.compose([Validators.required, whitespaceValidator()]),
                );
            }

            if (changes.dateOfBirth && this.dobControl.value !== this.minus18Years) {
                this.dateOfBirth = this.dobControl.value;
            }

            this.verificationInfoForm.controls.residentialZipCode.updateValueAndValidity();
            this.verificationInfoForm.controls.ssn.updateValueAndValidity();
            this.onFormChanges();
        });
    }

    // onFormSubmit
    public onFormSubmit(verificationFormEl: HTMLFormElement): void {
        if (this.bazaFormValidatorService.isFormValid(this.verificationInfoForm, verificationFormEl)) {
            const data: PersonalInformation = {
                ...this.verificationInfoForm.value,
                hasSsn: !this.verificationInfoForm.value.hasntSsn,
                phone: this.verificationInfoForm.value.phone,
                phoneCountryCode: this.phoneCountryCode,
                phoneCountryNumericCode: this.numericCode,
                residentialState: this.residentialState,
                ssn: !this.verificationInfoForm.value.hasntSsn ? this.verificationInfoForm.value.ssn.toString() : '',
            };

            // If SSN is not touched, Remove from Payload.
            // Only possible with Edit/Update flow.
            if (data.ssn.startsWith('**')) {
                delete data.ssn;
            }

            delete data.hasntSsn;

            if (
                data.hasSsn ||
                (!data.hasSsn && this.uploadedSSN.ssnDocumentFileName) ||
                (!data.hasSsn && this.fileList && this.fileList.length > 0)
            ) {
                this.store.dispatch(new ApplyPersonalInfo(data, this.fileList)).subscribe(() => {
                    this.moveToNextStep.next();
                });
            } else {
                this.notification.error(this.wts.getI18nLabel('dwvf.vf.notifications.verify_upload_doc'), '');
            }
        }
    }

    redirectToNextStep() {
        const queryParams = {
            back: this.vfConfig?.back,
        };

        if (this.vfConfig?.redirect) {
            queryParams['redirect'] = this.vfConfig?.redirect;
        }

        this.router.navigate([this.vfConfig?.vfLink, 'investor'], {
            queryParams: queryParams,
        });
    }

    checki18nValidationErrorMessages(control: UntypedFormControl, controlName: string): string {
        const validationsPreset: ValidationsPreset = new Map([
            [
                'residentialZipCode',
                [
                    { key: i18nValidationTypesEnum.onlynumbers, params: { length: 5 } },
                    { key: i18nValidationTypesEnum.minlength, params: { length: 5 } },
                    { key: i18nValidationTypesEnum.maxlength, params: { length: 5 } },
                    { key: i18nValidationTypesEnum.whiteSpace, params: { length: 5 } },
                ],
            ],
            ['dateOfBirth', [{ key: i18nValidationTypesEnum.isAdult, params: { age: 18 } }]],
            ['ssn', [{ key: i18nValidationTypesEnum.minlength, params: { length: 9 } }]],
        ]);

        return this.wts.geti18nValidationErrorMessages({
            control,
            controlName,
            i18nFormFieldsPath: this.i18nFormFieldsPath,
            i18nGenericValidationsPath: `${this.i18nFormPath}.genericValidators`,
            validationsPreset,
        });
    }
}
