import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { MktPageDto } from '@scaliolabs/baza-content-types-shared';

import { MarketingState, GetSections } from '../store';

@Injectable({ providedIn: 'root' })
export class MarketingResolver implements Resolve<MktPageDto[]> {
    constructor(private readonly store: Store) {}

    resolve(): Observable<MktPageDto[]> {
        const items = this.store.selectSnapshot(MarketingState.sections);

        return items.length > 0 ? of(items) : this.store.dispatch(new GetSections());
    }
}
