export const bazaCommonCmsEnI18n: Record<string, unknown> = {
    common: {
        services: {
            bazaNgConfirm: {
                title: '',
                confirmText: 'Proceed',
                rejectText: 'Cancel',
            },
        },
    },
};
