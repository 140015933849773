import { Injectable, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BazaNcBootstrapDto, BazaNcOfferingRegulationType } from '@scaliolabs/baza-nc-shared';
import { has } from 'lodash';
import { Subject } from 'rxjs';
import { CARD_BRAND_ICONS, ValidationsPreset, i18nValidationCheckReq } from '../models';
import { PriceNoRoundPipe } from '../pipes';
/**
 * A Baza level shared service for common utility functions
 */
@Injectable({ providedIn: 'root' })
export class BazaWebUtilSharedService {
    readonly ts = inject(TranslateService);
    readonly priceNoRound = inject(PriceNoRoundPipe);
    public readonly refreshInitData$: Subject<void> = new Subject<void>();

    dollarFormat = (value: number) => this.priceNoRound.transform(value);
    dollarParse = (value: string) => value.replace('$', '').replace(',', '');

    public geti18nValidationErrorMessages({
        control,
        controlName,
        i18nFormFieldsPath,
        i18nGenericValidationsPath,
        validationsPreset,
    }: {
        control: UntypedFormControl;
        controlName: string;
        i18nFormFieldsPath: string;
        i18nGenericValidationsPath: string;
        validationsPreset: ValidationsPreset;
    }): string {
        const fieldPath = `${i18nFormFieldsPath}.${controlName}`;

        const genericError = this.checki18nGenericValidations({
            control,
            formCtrlName: controlName,
            fieldPath,
            genericValidationsPath: i18nGenericValidationsPath,
        });

        if (genericError) {
            return genericError;
        }

        const error = [...validationsPreset.get(controlName)].find((error) => control.hasError(error.key));

        return error ? this.getI18nLabel(`${fieldPath}.validators`, error.key, error.params) : '';
    }

    private checki18nGenericValidations(validationReq: i18nValidationCheckReq): string {
        let errorMsg = '';

        if (!validationReq?.control?.pristine ?? true) {
            const genericValidations = this.ts.instant(validationReq.genericValidationsPath);
            const genericValidationKeys = genericValidations ? Object.keys(genericValidations) : [];
            const overrideValidations = this.geti18nOverrideValidations(validationReq);

            genericValidationKeys.forEach((validationType) => {
                if (validationReq?.control.hasError(validationType)) {
                    const fieldLabel = this.getI18nLabel(validationReq.fieldPath, 'label');
                    const i18nGenericValidationPath = validationReq.genericValidationsPath + (validationType ? `.${validationType}` : '');
                    const i18nOverrideValidationsPath =
                        `${validationReq.fieldPath}.validators` + (validationType ? `.${validationType}` : '');

                    if (overrideValidations && overrideValidations[`${validationType}`]) {
                        errorMsg = this.ts.instant(i18nOverrideValidationsPath);
                    } else {
                        errorMsg = this.ts.instant(i18nGenericValidationPath, { fieldLabel: fieldLabel });
                    }

                    return false;
                }
                return true;
            });
        }

        return errorMsg ?? '';
    }

    private geti18nOverrideValidations(validationReq: i18nValidationCheckReq) {
        const i18nField = this.ts.instant(validationReq.fieldPath);
        return has(i18nField, 'validators') ? i18nField.validators : null;
    }

    public getI18nLabel(i18nBasePath: string, key?: string, interpolatedParams?: Record<string, unknown>) {
        return this.ts.instant(`${i18nBasePath}${key ? `.${key}` : ''}`, interpolatedParams);
    }

    public getCardIcon(brand: string) {
        return CARD_BRAND_ICONS.find((x) => x.brand?.toLocaleLowerCase() === brand?.toLocaleLowerCase())?.icon ?? null;
    }

    // Utility Functions
    public isForeignInvestor(initData: BazaNcBootstrapDto): boolean {
        return initData?.investorAccount?.status?.isForeign ?? true;
    }

    public isVerifiedInvestor(initData: BazaNcBootstrapDto) {
        return initData?.investorAccount?.isAccountVerificationCompleted;
    }

    public isAccreditedInvestor(initData: BazaNcBootstrapDto) {
        return initData?.investorAccount?.isAccreditedInvestor;
    }

    public isDwollaAvailable(initData: BazaNcBootstrapDto): boolean {
        return initData?.investorAccount?.isDwollaAvailable ?? false;
    }

    public isDwollaCashInAccountLinked(initData: BazaNcBootstrapDto): boolean {
        return initData?.investorAccount?.isBankAccountCashInLinked ?? false;
    }

    public isDwollaCashOutAccountLinked(initData: BazaNcBootstrapDto): boolean {
        return initData?.investorAccount?.isBankAccountCashOutLinked ?? false;
    }

    public isNCBankAccountAvailable(initData: BazaNcBootstrapDto): boolean {
        return initData?.investorAccount?.isBankAccountNcAchLinked ?? false;
    }

    public isCardAvailable(initData: BazaNcBootstrapDto): boolean {
        return initData?.investorAccount?.isCreditCardLinked ?? false;
    }

    public appSupportsRegCF(initData: BazaNcBootstrapDto) {
        return initData?.supportedNcRegulationTypes?.includes(BazaNcOfferingRegulationType.RegCF);
    }

    /* Cross Browser Event Handler Utility */
    public attachEventListener(el, ev, fn) {
        if (window.addEventListener) {
            // modern browsers including IE9+
            el.addEventListener(ev, fn, false);
        } else if (window['attachEvent']) {
            // IE8 and below
            el.attachEvent('on' + ev, fn);
        } else {
            el['on' + ev] = fn;
        }
    }

    public removeEventListener(el, ev, fn) {
        if (window.removeEventListener) {
            el.removeEventListener(ev, fn, false);
        } else if (window['detachEvent']) {
            el.detachEvent('on' + ev, fn);
        } else {
            el['on' + ev] = null;
        }
    }
}
