import { BazaNcIntegrationFeedPostDto } from '@scaliolabs/baza-nc-integration-shared';

export class GetFeed {
    static readonly type = 'GetFeed';

    constructor(public lastId?: number) {}
}

export class GetFeedById {
    static readonly type = '[Feed] Get ById';

    constructor(public id: number) {}
}

export class MarkAllAsRead {
    static readonly type = 'MarkAllAsRead';

    constructor(public lastSeenId: number) {}
}

export class Applause {
    static readonly type = 'Applause';

    constructor(public post: BazaNcIntegrationFeedPostDto) {}
}

export class Unapplause {
    static readonly type = 'Unapplause';

    constructor(public post: BazaNcIntegrationFeedPostDto) {}
}

export class FeedHasAnyUpdates {
    static readonly type = 'FeedHasAnyUpdates';
}
