<nz-footer class="footer">
    <div class="container">
        <div
            nz-row
            nzGutter="30"
            nzAlign="middle">
            <div
                class="footer__wrap"
                nz-col
                nzXs="12">
                <app-footer-menu></app-footer-menu>

                <app-footer-social></app-footer-social>
            </div>

            <div
                nz-col
                nzXs="12">
                <nz-divider class="footer__divider"></nz-divider>
            </div>

            <div
                class="footer__wrap"
                nz-col
                nzXs="12">
                <app-footer-links></app-footer-links>

                <div class="footer__copy">&copy; {{ today | date : 'YYYY' }} Scalio. All rights reserved.</div>
            </div>
        </div>
    </div>
</nz-footer>
