import { Component, Input, Output, EventEmitter, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, filter, interval, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClearPurchaseState } from '@scaliolabs/baza-web-purchase-flow';
import { ClearVerificationState } from '@scaliolabs/baza-web-verification-flow';
import { Account, AccountState, FeedHasAnyUpdates, FeedState, Logout } from '@scaliolabs/web/data-access';

@UntilDestroy()
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit {
    @Select(FeedState.hasAnyUpdates)
    hasAnyUpdates$: Observable<boolean | null>;

    @Select(AccountState.account)
    user$: Observable<Account | null>;

    @Input()
    openedMenu: boolean;

    @Output()
    openedMenuChange = new EventEmitter<boolean>();

    isHeaderGhost$ = new BehaviorSubject<boolean>(false);

    private scrollListener = () => null;
    private hasTransparentHeader = false;

    constructor(private route: ActivatedRoute, private renderer: Renderer2, private router: Router, private store: Store) {}

    ngOnInit(): void {
        this.setHeaderTransparency();
        this.startUpdatesInterval();

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.setHeaderTransparency();
            });
    }

    /**
     * returns last child in the activated route in order to get data from it
     */
    private getLastRouteChild(): ActivatedRoute {
        let lastChild = this.route.firstChild;

        while (lastChild.firstChild) {
            lastChild = lastChild.firstChild;
        }

        return lastChild;
    }

    // setHeaderTransparency
    private setHeaderTransparency(): void {
        this.scrollListener(); // destroys previous listener

        this.hasTransparentHeader = this.getLastRouteChild().snapshot.data.layoutParams?.isHeaderGhost;

        if (this.hasTransparentHeader) {
            this.isHeaderGhost$.next(true);

            this.scrollListener = this.renderer.listen('window', 'scroll', this.updateHeaderTransparency);
        } else {
            this.isHeaderGhost$.next(false);
        }
    }

    // updateHeaderTransparency
    private updateHeaderTransparency = () => {
        this.isHeaderGhost$.next(
            !this.openedMenu &&
                window.pageYOffset === 0 &&
                // to avoid cases with open modal window
                document.documentElement.className !== 'cdk-global-scrollblock',
        );
    };

    // onLogout
    onLogout(): void {
        this.store.dispatch(new ClearPurchaseState());
        this.store.dispatch(new ClearVerificationState());

        this.store
            .dispatch(new Logout())
            .subscribe(() => this.router.navigate(['.'], { relativeTo: this.getLastRouteChild(), queryParams: { logout: true } }));
    }

    // onMenuToggle
    onMenuToggle(): void {
        this.openedMenu = !this.openedMenu;
        this.openedMenuChange.emit(this.openedMenu);

        if (this.openedMenu) {
            this.renderer.addClass(document.body, 'overflow');
        } else {
            this.renderer.removeClass(document.body, 'overflow');
        }

        if (this.hasTransparentHeader) {
            this.updateHeaderTransparency();
        }
    }

    // startUpdatesInterval
    private startUpdatesInterval(): void {
        interval(120000)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.store.dispatch(new FeedHasAnyUpdates());
            });
    }
}
