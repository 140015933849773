import { IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { NorthCapitalErrorCodes, NorthCapitalStatusOK } from './error-codes/north-capital-transact-api.error-codes';

export const BAZA_NC_TRANSACT_API_PRECISION = 6; // NC works with numbers like 1278.250000

export function isNorthCapitalResponse(input: NorthCapitalErrorResponse<unknown>): boolean {
    return !!input && typeof input === 'object' && 'statusCode' in input;
}

export function isNorthCapitalSuccess(input: NorthCapitalErrorResponse<unknown>): boolean {
    return isNorthCapitalResponse(input) && input.statusCode === NorthCapitalStatusOK;
}

export function getNcPayloadByteSize(payload: Record<string, any> | string, options?: { asKiloBytes: boolean }): number {
    const size = new Blob([JSON.stringify(payload)]).size;

    return options?.asKiloBytes ? size / 1000 : size;
}

export function isNorthCapitalError(input: NorthCapitalErrorResponse<unknown>, expectErrorStatus?: NorthCapitalErrorCodes): boolean {
    return expectErrorStatus
        ? isNorthCapitalResponse(input) && input.statusCode === expectErrorStatus
        : isNorthCapitalResponse(input) && !!input.statusCode && input.statusCode !== NorthCapitalErrorCodes.Ok;
}

export class NorthCapitalCredentials {
    @IsString()
    @IsNotEmpty()
    clientID: string;

    @IsString()
    @IsNotEmpty()
    developerAPIKey: string;
}

export class NorthCapitalBaseResponse<T = string> {
    @IsString()
    @IsNotEmpty()
    statusCode: 'Ok'; // === NorthCapitalStatusOK

    @IsNotEmpty()
    statusDesc: string | T;
}

export class NorthCapitalErrorResponse<T = NorthCapitalErrorCodes> {
    @IsString()
    @IsNotEmpty()
    statusCode: T;

    @IsString()
    @IsNotEmpty()
    statusDesc: string;

    @IsOptional()
    @IsString()
    'Error(s)'?: string;
}
