import { Component } from '@angular/core';
import { BazaRegistryNgService } from '@scaliolabs/baza-core-ng';

@Component({
    selector: 'app-footer-social',
    templateUrl: './footer-social.component.html',
    styleUrls: ['./footer-social.component.less'],
})
export class FooterSocialComponent {
    constructor(private readonly registry: BazaRegistryNgService) {}

    public getSocialMedia(): { [index: string]: string } {
        return this.registry.value('socialMedia');
    }
}
